import React, { useState } from "react";
import {
	CustomIFrame,
	Warning,
	WarningButton,
	WarningHeader,
	WarningText,
} from "./ProjectXElements";

export const ProjectX = () => {
	const [warned, setWarned] = useState(false);

	const handleWarning = () => {
		setWarned(true);
	};
	return (
		<>
			<CustomIFrame src="https://perfect-fit-demo.herokuapp.com/" />
			{!warned && (
				<Warning>
					<WarningHeader>Attention!</WarningHeader>
					<WarningText>
						This may take some seconds to load in case the server is currently
						ideling.
					</WarningText>
					<WarningButton onClick={handleWarning}>OK!</WarningButton>
				</Warning>
			)}
		</>
	);
};
