import styled from "styled-components";
import { BsBookmarkCheck } from "react-icons/bs";
export const HardSkillsHeader = styled.h2`
	background-color: var(--dark);
	padding: 10px 30px;
	margin-bottom: 20px;
	border-radius: 5px;
	box-shadow: var(--shadow);
	@media screen and (max-width: 767px) {
		margin-top: 50px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.8rem;
		margin-top: 20px;
	}
`;
export const HardSkillIntroduction = styled.p`
	margin-bottom: 20px;
	@media screen and (max-width: 480px) {
		font-size: 1.2rem;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.2rem;
	}
`;
export const HardSkillList = styled.ul`
	width: 100%;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	padding: 0px 20px;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	@media screen and (max-width: 480px) {
		margin-top: 0px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 60%;
	}
`;
export const HardSkillListElement = styled.li`
	list-style: none;
	background-color: var(--dark);
	padding: 10px 15px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	@media screen and (max-width: 480px) {
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	}
`;
export const HardSkill = styled.p`
	@media screen and (max-width: 480px) {
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	}
`;

export const HardSkillCheck = styled(BsBookmarkCheck)`
	margin-left: 10px;
	color: var(--contrast);
	@media screen and (max-width: 480px) {
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	}
`;
