import styled from "styled-components";

export const CustomIFrame = styled.iframe`
	width: 100%;
	height: 100%;
`;
export const Warning = styled.div`
	width: 300px;
	height: 200px;
	position: absolute;
	top: calc(50% - 150px);
	left: calc(50% - 150px);
	background: red;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 10px 20px;
	background-color: #ffffff10;
	backdrop-filter: blur(22px);
	--webkit-backdrop-filter: blur(22px);
	border-radius: 5px;
	border-bottom: 2px solid var(--contrast);
	box-shadow: var(--shadow2);
`;
export const WarningHeader = styled.h3`
	font-size: 1.5rem;
`;
export const WarningText = styled.p`
	font-size: 1.1rem;
	text-align: center;
`;
export const WarningButton = styled.button`
	padding: 10px 40px;
	background-color: #ffffff90;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: bold;
	color: #ffffff;
	border: none;
	box-shadow: var(--shadow);
	&:hover {
		background-color: var(--contrast);
	}
`;
