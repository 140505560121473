import styled from "styled-components";
import { Link } from "react-router-dom";

// TODO: Z-index-problem!!!!!
export const NavWrapper = styled.div`
	z-index: 6;
	position: absolute;
	top: 10px;
	left: 40px;
	padding: 10px;
	width: ${(props) => (props.active ? "auto" : "40px")};
	max-height: ${(props) => (props.active ? "auto" : "40px")};
	height: auto;
	background-color: ${(props) => (props.active ? "#ffffff10" : "")};
	backdrop-filter: ${(props) => (props.active ? "blur(10px)" : "")};
	transition: all 2s cubic-bezier(0.86, 0.29, 0.25, 1);
	border-radius: 5px;

	@media screen and (max-width: 480px) {
		background-color: ${(props) => (props.active ? "#ffffff10" : "")};
		backdrop-filter: ${(props) => (props.active ? "blur(10px)" : "")};
		border-radius: 5px;
		top: 10px;
		left: 10px;
	}

	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		background-color: ${(props) => (props.active ? "#ffffff10" : "")};
		backdrop-filter: ${(props) => (props.active ? "blur(10px)" : "")};
		border-radius: 5px;
		top: 20px;
		left: 20px;
	}
`;
export const NavButtonWrapper = styled.div`
	height: 30px;
	width: 30px;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	transition: all 0.5s ease;
	transform: ${(props) => (props.active ? "rotate(90deg)" : "")};
`;
export const NavDiv1 = styled.div`
	position: absolute;
	transition: all 0.5s ease;
	-webkit-transition-delay: 1s, 1s;
	transition-delay: 1s, 1s;
	transition-property: width, transform;
	top: 0;
	width: ${(props) => (props.active ? "30px" : "10px")};
	transform: ${(props) => (props.active ? "translate(0px, 10px) rotate(45deg)" : "")};

	height: 3px;
	background-color: var(--bright);
`;
export const NavDiv2 = styled.div`
	position: absolute;
	transition: width 0.5s ease;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
	transition-property: width;

	top: 10px;
	width: ${(props) => (props.active ? "0px" : "30px")};
	height: 3px;
	background-color: var(--bright);
`;
export const NavDiv3 = styled.div`
	transition: all 0.5s ease;
	-webkit-transition-delay: 1s, 1s;
	transition-delay: 1s, 1s;
	transition-property: width, transform;
	position: absolute;
	top: 20px;
	width: ${(props) => (props.active ? "30px" : "20px")};
	transform: ${(props) => (props.active ? "translate(0px, -10px) rotate(-45deg)" : "")};
	height: 3px;
	background-color: var(--bright);
`;
export const NavSection = styled.nav`
	transition: visibility 1s ease;
	visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;
export const NavUL = styled.ul`
	padding: 0px 0px;
	padding-top: 40px;
	padding-bottom: 20px;

	width: 200px;
`;
export const NavLI = styled.li`
	list-style: none;
	margin-bottom: 10px;
	position: relative;
	transition: all 0.2s ease;
	-webkit-transition-delay: ${(props) => props.delay};
	transition-delay: ${(props) => props.delay};
	transition-property: left;
	left: ${(props) => (props.active ? "0px" : "-300px")};
	&:hover {
		background-color: #ffffff10;
		backdrop-filter: blur(12px);
		border-radius: 5px;
	}
`;
export const NavLink = styled(Link)`
	text-decoration: none;
	letter-spacing: 8px;
	font-size: 1.5rem;
	transition: all 0.2s ease;
	position: relative;
	left: 0px;
	&:hover {
		color: var(--contrast);
		left: 10px;
	}
`;

export const MailLink = styled.a`
	text-decoration: none;
	letter-spacing: 8px;
	font-size: 1.5rem;
	transition: all 0.2s ease;
	position: relative;
	left: 0px;
	&:hover {
		color: var(--contrast);
		left: 10px;
	}
`;
