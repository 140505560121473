import React, { useEffect, useState } from "react";
import { useIntersect } from "../../CustomHooks/useIntersect";
import {
	Blur,
	CustomHR,
	HeaderDate,
	ProjectContainer,
	ProjectDetailDescription,
	ProjectDetailDescriptionWrapper,
	ProjectDetailWrapper,
	ProjectHardSkillsContainer,
	ProjectHeading,
	ProjectShowCaseContainer,
	ProjectSidebar,
	ProjectStory,
	ProjectStoryHeading,
	ProjectStoryWrapper,
	ProjectThumbnail,
	ProjectThumbnailWrapper,
	ProjectWrapper,
	SideBarButton,
	SideBarButtonArrow0,
	SideBarButtonArrow1,
} from "./ProjectComponentElements";
import ProjectHardSkillsComponent from "./ProjectHardSkills";
import ProjectShowCaseComponent from "./ProjectShowCase";

export const ProjectComponent = (props) => {
	window.addEventListener(
		"keydown",
		function (e) {
			if (
				["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
					e.code
				) > -1
			) {
				e.preventDefault();
			}
		},
		false
	);
	const [sidebarState, setSidebarState] = useState(0);
	const [thumbnail, entry1, thumbnailIntersect] = useIntersect({});
	const [detailDescription, entry2, detailDescriptionIntersect] = useIntersect({});
	const [story, entry3, storyIntersect] = useIntersect({});

	useEffect(() => {
		if (props.forceState === 1) {
			setSidebarState(1);
		}
		if (props.forceState === 2) {
			setSidebarState(2);
		}
		if (props.forceState === 0) {
			setSidebarState(0);
		}
	}, [props.forceState]);

	const handleSliderState = (buttonID) => {
		switch (buttonID) {
			case 0:
				if (sidebarState === 0) {
					setSidebarState(1);
				} else {
					setSidebarState(0);
				}
				break;
			case 1:
				if (sidebarState === 1) {
					setSidebarState(2);
				} else {
					setSidebarState(1);
				}
				break;
		}
	};
	return (
		<ProjectContainer state={sidebarState} id={props.project.id}>
			<ProjectWrapper>
				<Blur sidebarState={sidebarState}></Blur>
				<ProjectSidebar sidebarState={sidebarState}>
					<ProjectShowCaseContainer>
						<ProjectShowCaseComponent
							sidebarState={sidebarState}
							content={props.project.projectShowCaseContent}
						/>
					</ProjectShowCaseContainer>
					<SideBarButton onClick={() => handleSliderState(1)}>
						<SideBarButtonArrow1 sidebarState={sidebarState} />
					</SideBarButton>
					<ProjectHardSkillsContainer>
						<ProjectHardSkillsComponent
							hardSkills={props.project.hardSkills}
						/>
					</ProjectHardSkillsContainer>
					<SideBarButton onClick={() => handleSliderState(0)}>
						<SideBarButtonArrow0 sidebarState={sidebarState} />
					</SideBarButton>
				</ProjectSidebar>
				<ProjectDetailWrapper>
					<ProjectThumbnailWrapper
						ref={thumbnail}
						intersecting={thumbnailIntersect}>
						<ProjectThumbnail src={props.project.thumbnail} />
					</ProjectThumbnailWrapper>
					<ProjectDetailDescriptionWrapper
						ref={detailDescription}
						intersecting={detailDescriptionIntersect}>
						<ProjectHeading>
							{props.project.name}{" "}
							<HeaderDate>
								({props.project.month} {props.project.year} )
							</HeaderDate>
						</ProjectHeading>
						<ProjectDetailDescription>
							{props.project.text1}
						</ProjectDetailDescription>
					</ProjectDetailDescriptionWrapper>
				</ProjectDetailWrapper>
				<ProjectStoryWrapper ref={story} intersecting={storyIntersect}>
					<ProjectStoryHeading>Story</ProjectStoryHeading>
					<ProjectStory>{props.project.text2}</ProjectStory>
					<CustomHR></CustomHR>
				</ProjectStoryWrapper>
			</ProjectWrapper>
		</ProjectContainer>
	);
};
