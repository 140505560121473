import React, { useContext, useEffect, useRef } from "react";
import {
	GameInstructionHeading,
	GameInstructionsWrapper,
	GameInstructionText,
	GameScreen,
	MobileDescription,
	ProjectGIF,
	ProjectMobileShowCase,
	ProjectShowCaseWrapper,
} from "./ProjectJumpElements";
import gif from "../../../videos/gif/ProjectJump.gif";
import { TouchContext } from "../../../Contexts/TouchContextProvider";
export const ProjectJump = () => {
	const touch = useContext(TouchContext);
	const id = useRef(null);
	const can = useRef();
	console.log(can);
	useEffect(() => {
		if (can.current) {
			var context, controller, update, enemy, player, bonus, gg, draw;

			context = document.getElementById("jumpScreen").getContext("2d");

			context.canvas.width = 860;
			context.canvas.height = 460;

			var score = 0;
			var lastScore = -1;

			var gameState = true;

			player = {
				x: 50,
				y: 100,
				width: 32,
				height: 32,
				velocityX: 0,
				velocityY: 0,
				vita: 1,
				jumpStrenght: 18,
				isJumping: false,
			};

			enemy = {
				x: context.canvas.width + 32,
				y: 0,
				width: 32,
				height: 0,
				velocityX: 0,
				velocityY: 0,
				hasSpawned: false,
			};

			function enemyInt(i) {
				enemy.height = (32 + Math.random() * 8) * i;
				enemy.width = (32 + Math.random() * 96) * i;
				enemy.y = context.canvas.height - 160 - (enemy.height - player.height);
				enemy.x = context.canvas.width + 32;
				enemy.velocityX = -(8 + Math.random() * 4) * 0.5 * i;
				enemy.hasSpawned = true;
			}

			bonus = {
				x: 350,
				y: context.canvas.height - 144,
				width: 16,
				height: 16,
				appear: false,
				allowedAppear: true,
			};

			function bonusInt() {
				bonus.x = Math.random() * (context.canvas.width - bonus.width);
				bonus.y = context.canvas.height - 144 - Math.random() * 100;

				bonus.appear = true;
			}

			controller = {
				left: false,
				right: false,
				up: false,
				click: false,

				buttonPress: function (event) {
					var status = event.type == "keydown" ? true : false;

					switch (event.keyCode) {
						case 65: //left
							controller.left = status;
							break;
						case 68: //right
							controller.right = status;
							break;
						case 87: //up
							controller.up = status;
							break;
					}
				},
				mouseX: 0,
				mouseY: 0,

				xButton: function () {
					if (this.mouseX >= 300 && this.mouseX <= 500) {
						return true;
					}
				},
				yButton: function () {
					if (this.mouseY >= 360 && this.mouseY <= 430) {
						return true;
					}
				},
			};

			function hitX(element) {
				if (
					player.x + player.width >= element.x &&
					player.x <= element.x + element.width
				) {
					return true;
				}
			}

			function hitY(element) {
				if (
					player.y + player.height >= element.y &&
					player.y <= element.y + element.height
				) {
					return true;
				}
			}

			function collision(element) {
				if (hitX(element) == true && hitY(element) == true) {
					return true;
				}
			}

			update = function () {
				if (gameState == true) {
					if (controller.left) {
						player.velocityX -= player.vita;
					}
					if (controller.right) {
						player.velocityX += player.vita;
					}

					if (controller.up && player.isJumping == false) {
						player.velocityY -= player.jumpStrenght;
						player.isJumping = true;
					}

					if (score % 2 == 0) {
						lastScore = score;
					}

					//enemie Movement
					var i = 1 + 0.05 * score;
					if (enemy.hasSpawned == false) {
						enemyInt(i);
					}
					if (enemy.x < 0 - enemy.width && enemy.hasSpawned == true) {
						enemy.hasSpawned = false;
						score += 1;
						i += 0.1;
					}

					//bonus
					if (score % 2 == 0 && bonus.appear === false && score != lastScore) {
						bonusInt();
					}

					//collision
					if (collision(enemy) == true) {
						gameState = false;
					}

					if (collision(bonus) === true) {
						bonus.x = context.canvas.width;
						bonus.appear = false;
						player.jumpStrenght *= 1.025;
						//bonus.allowedAppear = false;
					}

					//physics
					//player
					//Bewegungsverhalten Y
					player.velocityY += 1;
					player.y += player.velocityY;
					//Bewegungsverhalten X
					player.x += player.velocityX;

					//reibung
					player.velocityY *= 0.9;
					player.velocityX *= 0.9;

					//enemy
					enemy.x += enemy.velocityX;

					//boundaries
					//floor
					//player
					if (player.y > context.canvas.height - 160) {
						player.y = context.canvas.height - 160;
						player.velocityY = 0;
						player.isJumping = false;
					}
					//enemies

					//player boundaries
					if (player.x < 0) {
						player.x = 0;
					}
					if (player.x > context.canvas.width - player.width) {
						player.x = context.canvas.width - player.width;
					}

					draw();
				}

				gg();
				if (
					gameState == false &&
					controller.xButton() &&
					controller.yButton() &&
					controller.click
				) {
					score = 0;
					lastScore = -1;

					gameState = true;

					player = {
						x: 50,
						y: 100,
						width: 32,
						height: 32,
						velocityX: 0,
						velocityY: 0,
						vita: 1,
						jumpStrenght: 18,
						isJumping: false,
					};

					enemy = {
						x: context.canvas.width + 32,
						y: 0,
						width: 32,
						height: 0,
						velocityX: 0,
						velocityY: 0,
						hasSpawned: false,
					};
					bonus = {
						x: 350,
						y: context.canvas.height - 144,
						width: 16,
						height: 16,
						appear: false,
						allowedAppear: true,
					};
					controller = {
						left: false,
						right: false,
						up: false,
						click: false,

						buttonPress: function (event) {
							var status = event.type == "keydown" ? true : false;

							switch (event.keyCode) {
								case 65: //left
									controller.left = status;
									break;
								case 68: //right
									controller.right = status;
									break;
								case 87: //up
									controller.up = status;
									break;
							}
						},
						mouseX: 0,
						mouseY: 0,

						xButton: function () {
							if (this.mouseX >= 300 && this.mouseX <= 500) {
								return true;
							}
						},
						yButton: function () {
							if (this.mouseY >= 360 && this.mouseY <= 430) {
								return true;
							}
						},
					};
				}
				id.current = window.requestAnimationFrame(update);
				console.log("running");
			};

			draw = function () {
				if (gameState == true) {
					//canvas color
					context.fillStyle = "#202020";
					context.fillRect(0, 0, context.canvas.width, context.canvas.height);

					//player color
					context.fillStyle = "#0080FF";
					context.fillRect(player.x, player.y, player.width, player.height);

					//bonus color
					context.fillStyle = "#77f442";
					context.fillRect(bonus.x, bonus.y, bonus.width, bonus.height);

					//enemy color
					context.fillStyle = "#f74733";
					context.fillRect(enemy.x, enemy.y, enemy.width, enemy.height);

					//score
					context.font = "20px Georgia";
					context.fillText("Score:" + score, context.canvas.width - 200, 40);
				}
			};

			gg = function () {
				if (gameState == false) {
					context.clearRect(0, 0, context.canvas.width, context.canvas.height);
					context.fillStyle = "#202020";
					context.fillRect(0, 0, context.canvas.width, context.canvas.height);

					context.fillStyle = "#f74733";
					context.font = "60px Georgia";
					context.fillText(
						"Game Over",
						context.canvas.width - 620,
						context.canvas.height / 2
					);

					context.font = "20px Georgia";
					context.fillText(
						"Your Score:" + "" + score,
						context.canvas.width - 400,
						context.canvas.height - 200
					);

					context.fillStyle = "#585858";
					context.fillRect(300, 360, 200, 70);

					context.fillStyle = "#f74733";
					context.font = "20px Georgia";
					context.fillText("RESTART", 360, 400);
				}
			};

			window.addEventListener("keydown", controller.buttonPress);
			window.addEventListener("keyup", controller.buttonPress);
			context.canvas.addEventListener("mousemove", function (e) {
				controller.mouseX = e.offsetX;
				controller.mouseY = e.offsetY;
			});
			context.canvas.addEventListener("mousedown", function (e) {
				controller.click = true;
			});
			context.canvas.addEventListener("mouseup", function (e) {
				controller.click = false;
			});
			update();
		}
	}, []);
	useEffect(() => {
		return () => window.cancelAnimationFrame(id.current);
	}, []);
	return (
		<>
			{!touch ? (
				<ProjectShowCaseWrapper>
					<GameScreen ref={can} id="jumpScreen"></GameScreen>
					<GameInstructionsWrapper>
						<GameInstructionHeading>Ziel: </GameInstructionHeading>
						<GameInstructionText>
							Dont hit the evil rectangles. Collect apples for higher jumps
							and more speed.
						</GameInstructionText>
						<GameInstructionHeading>Controlls: </GameInstructionHeading>
						<GameInstructionText>Jump: W</GameInstructionText>
						<GameInstructionText>Left: A</GameInstructionText>
						<GameInstructionText>Right: D</GameInstructionText>
					</GameInstructionsWrapper>
				</ProjectShowCaseWrapper>
			) : (
				<ProjectMobileShowCase>
					<ProjectGIF src={gif}></ProjectGIF>
					<MobileDescription>
						Unfortunetly this showcase is not available on mobile devices.
						Feel free to switch to a desktop pc in order to play the game.
					</MobileDescription>
				</ProjectMobileShowCase>
			)}
		</>
	);
};
