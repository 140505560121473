import React from "react";
import {
	BackToTop,
	BackToTopContainer,
	BackToTopIcon,
	NavContainer,
	NavDate,
	NavEntry,
	NavIcon,
} from "./ProjectNavElements";

export const ProjectNav = (props) => {
	return (
		<NavContainer>
			<BackToTopContainer
				scrollPos={props.scrollPos + props.windowHeight / 2}
				windowHeight={props.windowHeight}
				href="#ProjectLanding">
				<BackToTopIcon />
			</BackToTopContainer>
			{props.projects.map((project, idx) => (
				<NavEntry
					key={idx}
					nr={idx + 1}
					scrollPos={props.scrollPos + props.windowHeight / 2}
					windowHeight={props.windowHeight}
					href={`#${project.id}`}>
					<NavDate>
						{project.month}
						<br />
						{project.year}
					</NavDate>
					<NavIcon></NavIcon>
				</NavEntry>
			))}
		</NavContainer>
	);
};
