import styled from "styled-components";

export const GameScreen = styled.canvas`
	height: 100%;
	aspect-ratio: 4/3;
`;

export const PropImages = styled.img`
	display: none;
`;
