import styled from "styled-components";

export const ProjectLandingContainer = styled.div`
	position: relative;
	height: 100vh;
	width: 100vw;
	scroll-snap-align: start;
	display: flex;
	padding: 50px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 767px) {
		padding: 100px 0px;
		scroll-snap-align: none;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		scroll-snap-align: none;
	}
`;
export const ProjectLandingHeader = styled.h1`
	font-size: 4rem;
	padding: 0px 20px;
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-radius: 5px;
	box-shadow: var(--shadow);
	margin-bottom: 40px;
	letter-spacing: 10px;
	border-bottom: 2px solid var(--contrast);
	@media screen and (max-width: 767px) {
		font-size: 3rem;
	}
`;
export const ProjectLandingDescription = styled.p`
	width: 600px;
	text-align: center;
	margin-bottom: 40px;
	transition: opacity 3s ease;
	opacity: ${(props) => (props.intersecting ? "1" : "0")};

	@media screen and (max-width: 767px) {
		width: 80%;
	}
`;
export const ProjectLandingGalery = styled.div`
	display: flex;
	width: 640px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 20px;
	transition: all 1000ms cubic-bezier(0.75, 0.21, 1, 1);
	opacity: ${(props) => (props.intersecting ? "1" : "0.1")};
	transform: ${(props) =>
		props.intersecting ? "translate(0,0)" : "translate(+50%, 0)"};
	@media screen and (max-width: 767px) {
		margin-top: 5%;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0px 100px;
		width: 100%;
		flex-wrap: nowrap;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		margin-top: 5%;
		align-items: flex-start;
	}
	@media screen and (max-device-height: 800px) {
		margin-top: 5%;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 0px 100px;
		width: 100%;
		flex-wrap: nowrap;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;
	}
`;
export const ProjectGalaryThumbnailWrapper = styled.a`
	position: relative;
	z-index: 0;
	transition: all 0.3s ease;
	cursor: pointer;
	height: 200px;
	width: 200px;
	box-shadow: var(--shadow);
	background-color: var(--bright);
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		transform: scale(1.01);
	}
	@media screen and (max-width: 767px) {
		scroll-snap-align: start;
	}
`;
export const ProjectGalaryThumbnail = styled.img`
	max-height: 200px;
	max-width: 200px;
	min-width: 200px;
`;
export const ProjectGalaryTitle = styled.p`
	position: absolute;
	z-index: 1;
	bottom: 0;
	width: 100%;
	padding: 10px 0px;
	text-align: center;
	background: rgba(0, 0, 0, 0.4);
	text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.75);
`;
