import styled from "styled-components";

export const ProjectShowCaseWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 480px) {
		display: none;
		&.particles {
			display: flex;
		}
	}

	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		max-width: 100%;
	}
`;

export const GameScreen = styled.canvas`
	margin: 20px;
	width: 840px;
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 100%;
	}
	&.particles {
		width: 100%;
		height: 100%;
		margin: 0px;
	}
`;

export const GameInstructionsWrapper = styled.div`
	width: 40%;
	background: var(--dark);
	padding: 20px;
	border: 1px solid var(--contrast);
`;
export const GameInstructionHeading = styled.h2`
	font-size: 1.2rem;
	font-weight: 500;
`;
export const GameInstructionText = styled.h2`
	font-size: 1rem;
	font-weight: 400;
	margin-left: 15px;
`;

export const ProjectMobileShowCase = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	@media screen and (max-width: 480px) {
		display: flex;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		display: flex;
	}
`;
export const ProjectGIF = styled.img`
	max-height: 60%;
	width: auto;
	max-width: 90%;
`;
export const MobileDescription = styled.div`
	width: 90%;
	padding: 15px;
	background-color: #ffffff60;
	backdrop-filter: blur(4px);
	border-bottom: 2px solid var(--contrast);
	color: var(--text-bright);
	text-align: center;
`;
