import React from "react";
import { useIntersect } from "../../CustomHooks/useIntersect";
import Navigation from "../Navigation";
import {
	ProjectGalaryThumbnail,
	ProjectGalaryThumbnailWrapper,
	ProjectGalaryTitle,
	ProjectLandingContainer,
	ProjectLandingDescription,
	ProjectLandingGalery,
	ProjectLandingHeader,
} from "./ProjectLandingElements";

export const ProjectLandingComponent = (props) => {
	const [description, entry1, descriptionIntersect] = useIntersect({
		rootMargin: "100px",
	});
	const [galary, entry2, galaryIntersect] = useIntersect({
		rootMargin: "0px +200%",
	});

	return (
		<ProjectLandingContainer id="ProjectLanding">
			<Navigation />

			<ProjectLandingHeader>PROJECTS</ProjectLandingHeader>
			<ProjectLandingDescription
				ref={description}
				intersecting={descriptionIntersect}>
				The following is a selection of projects to showcase my progression into
				the world of development.
			</ProjectLandingDescription>
			<ProjectLandingGalery ref={galary} intersecting={galaryIntersect}>
				{props.projects.map((project) => (
					<ProjectGalaryThumbnailWrapper href={`#${project.id}`}>
						<ProjectGalaryTitle>{project.name}</ProjectGalaryTitle>
						<ProjectGalaryThumbnail
							src={project.thumbnail}></ProjectGalaryThumbnail>
					</ProjectGalaryThumbnailWrapper>
				))}
			</ProjectLandingGalery>
		</ProjectLandingContainer>
	);
};
