import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
export const ProjectsContainer = styled.div`
	min-height: 100vh;
	height: 100vh;
	min-width: 100vw;
	overflow-x: hidden;
	overflow-y: auto;
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
	background: var(--gradient-fallback); /* fallback for old browsers */
	background: var(--gradient-bg-webkit);
	background: var(
		--gradient-bg
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	scroll-snap-stop: always;
	scrollbar-color: var(--contrast) var(--dark-sec);
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		background: var(--dark-sec);
	}
	&::-webkit-scrollbar-thumb {
		background: var(--contrast);
		border-radius: 5px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	@media screen and (max-width: 480px) {
		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-track {
			background: var(--dark-sec);
		}
		&::-webkit-scrollbar-thumb {
			background: var(--contrast);
			border-radius: 3px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
`;

export const ProjectLanding = styled.div`
	height: 100vh;
	width: 100vw;
	scroll-snap-align: start;
`;
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ! Introduction !!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const IntroWrapper = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 1000;
	/* display: flex;
	justify-content: center;
	align-items: center; */

	animation-name: appearing;
	animation-duration: 1000ms;
	@keyframes appearing {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;
export const IntroBlur = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${(props) => (props.introState === 2 ? "#00000050" : "#00000099")};
`;
export const IntroWindow = styled.div`
	position: absolute;
	top: ${(props) =>
		props.introState === 1
			? ""
			: props.introState === 2
			? ""
			: props.introState === 3
			? ""
			: ""};
	bottom: ${(props) =>
		props.introState === 1
			? "40vh"
			: props.introState === 2
			? "10vh"
			: props.introState === 3
			? "40vh"
			: "10vh"};
	left: ${(props) =>
		props.introState === 1
			? "50vw"
			: props.introState === 2
			? "50vw"
			: props.introState === 3
			? "70vw"
			: "50vw"};
	right: ${(props) =>
		props.introState === 1
			? ""
			: props.introState === 2
			? ""
			: props.introState === 3
			? ""
			: ""};
	transform: translate(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: #ffffff30;
	backdrop-filter: blur(10px);
	width: ${(props) =>
		props.introState === 1
			? "600px"
			: props.introState === 2
			? "1000px"
			: props.introState === 3
			? "300px"
			: "1000px"};
	max-width: 90vw;
	padding: 50px;
	padding-top: 30px;
	padding-bottom: 20px;
	border-radius: 5px;
	transition: all 1500ms ease;
	@media screen and (max-width: 767px) {
		max-width: 80vw;

		width: ${(props) =>
			props.introState === 1
				? "600px"
				: props.introState === 2
				? "600px"
				: props.introState === 3
				? "600px"
				: "600px"};

		top: ${(props) =>
			props.introState === 1
				? ""
				: props.introState === 2
				? ""
				: props.introState === 3
				? ""
				: ""};
		bottom: ${(props) =>
			props.introState === 1
				? "40vh"
				: props.introState === 2
				? "30vh"
				: props.introState === 3
				? "20vh"
				: "15vh"};
		left: ${(props) =>
			props.introState === 1
				? "50vw"
				: props.introState === 2
				? "50vw"
				: props.introState === 3
				? "50vw"
				: "45vw"};
		right: ${(props) =>
			props.introState === 1
				? ""
				: props.introState === 2
				? ""
				: props.introState === 3
				? ""
				: ""};
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: ${(props) =>
			props.introState === 1
				? "600px"
				: props.introState === 2
				? "1000px"
				: props.introState === 3
				? "600px"
				: "1000px"};
		top: ${(props) =>
			props.introState === 1
				? ""
				: props.introState === 2
				? ""
				: props.introState === 3
				? ""
				: ""};
		bottom: ${(props) =>
			props.introState === 1
				? "40vh"
				: props.introState === 2
				? "10vh"
				: props.introState === 3
				? "20vh"
				: "10vh"};
		left: ${(props) =>
			props.introState === 1
				? "50vw"
				: props.introState === 2
				? "50vw"
				: props.introState === 3
				? "50vw"
				: "50vw"};
		right: ${(props) =>
			props.introState === 1
				? ""
				: props.introState === 2
				? ""
				: props.introState === 3
				? ""
				: ""};
	}
`;

export const CloseButton = styled(AiOutlineClose)`
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 2rem;
	color: #ffffff;
	cursor: pointer;
	&:hover {
		color: var(--contrast);
	}
`;

export const ProgressStatus = styled.p`
	position: absolute;
	bottom: 20px;
	right: 20px;
	color: var(--contrast);
`;
export const IntroWindowHeadline = styled.h1`
	font-size: 2rem;
	margin-bottom: 20px;
	color: var(--contrast);
`;
export const IntroWindowText = styled.p`
	margin-bottom: 40px;
	text-align: center;
`;
export const IntroWindowButton = styled.button`
	padding: 10px 30px;
	border-radius: 5px;
	border: none;
	border-bottom: 1px solid var(--contrast);
	background-color: var(--bright-o);
	color: var(--text-bright);
	font-size: 1.2rem;
	box-shadow: var(--shadow2);
	cursor: pointer;
	transition: all 250ms ease;
	&:hover {
		font-weight: bold;
		background-color: var(--contrast);
		color: var(--bright-o);
		border-bottom: 1px solid var(--bright-o);
	}
`;
