import styled from "styled-components";

export const AboutContainer = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
	background: var(--gradient-fallback); /* fallback for old browsers */
	background: var(--gradient-bg-webkit);
	background: var(--gradient-bg);
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	/* scroll-snap-type: y mandatory; */
	scroll-behavior: smooth;
	/* scroll-snap-stop: always; */
	&::-webkit-scrollbar {
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		background: var(--dark-sec);
	}
	&::-webkit-scrollbar-thumb {
		background: var(--contrast);
		border-radius: 5px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
	@media screen and (max-width: 767px) {
		scroll-snap-type: none;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		scroll-snap-type: none;
	}
`;
export const AboutLanding = styled.div`
	min-height: 100vh;
	width: 100%;
	max-width: 1500px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 40px 0px;
	/* scroll-snap-align: start; */
	@media screen and (max-width: 767px) {
		min-height: initial;
		width: 100vw;
		display: grid;
		grid-template-columns: 100vw;
		grid-template-rows: auto 1fr 1fr;
		justify-content: center;
		grid-template-areas:
			"h"
			"i"
			"a";
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		min-height: initial;
	}
`;
export const AboutHeading = styled.h1`
	font-size: 3rem;
	padding: 0px 20px;
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-bottom: 2px solid var(--contrast);

	border-radius: 5px;
	box-shadow: var(--shadow);
	margin-bottom: 20px;
	letter-spacing: 10px;
	@media screen and (max-width: 767px) {
		width: 90vw;
		margin: 0px auto;
		margin-top: 20px;
		text-align: center;
		grid-area: h;
		font-size: 3rem;
	}
`;
export const AttributeContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 767px) {
		grid-area: a;
		overflow-x: scroll;
		flex-direction: column;
	}
`;
export const AttributeWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	margin: 40px;
	padding: 10px 10px;
	border-radius: 5px;
	width: 250px;
	height: 200px;
	&.appearLeft {
		transition: all 1000ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(-50%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}

	&.appearRight {
		transition: all 1000ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(+50%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	&.appearMiddle {
		transition: all 1000ms ease-in;
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	@media screen and (max-width: 767px) {
		height: auto;
		justify-content: center;
		padding: 0px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 200px;
		margin: 20px;
		height: initial;
	}
`;

export const AttributeIcon = styled.div`
	background-color: #ffffff20;
	backdrop-filter: blur(2px);
	--webkit-backdrop-filter: blur(2px);
	border-bottom: 2px solid var(--contrast);
	height: 80px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	color: var(--contrast);
	font-size: 3rem;
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		height: 100px;
		width: 100px;
	}
`;
export const AttributeTextH2 = styled.h2`
	font-size: 1rem;
	margin: 10px;
	text-align: center;
	text-shadow: 0px 0px 6px rgba(228, 87, 46, 0.4);
	@media screen and (max-width: 767px) {
		margin: 0px;
		font-size: 1.2rem;
		margin-bottom: 10px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.2rem;
	}
`;
export const AttributeTextP = styled.p`
	font-size: 0.8rem;
	text-align: center;
	@media screen and (max-width: 767px) {
		font-size: 1.2rem;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1rem;
	}
`;
export const AboutInfoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 20px;
	@media screen and (max-width: 767px) {
		grid-area: i;
		width: 100%;
		margin: 0px;
		margin-top: 10px;
		flex-direction: column;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		margin-top: 100px;
	}
`;
export const AboutMainInfo = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0px 5%;
	padding: 10px 0px;
	width: 30%;
	border-radius: 5px;
	&.appearLeft {
		transition: all 1000ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(-100%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	@media screen and (max-width: 767px) {
		width: 90%;
		margin-bottom: 20px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 50%;
		margin: 0;
		padding: 0;
	}
`;
export const AboutInfoImageWrapper = styled.div`
	height: 302px;
	padding-bottom: 2px;
	border-bottom: 2px solid var(--contrast);
	border-radius: 4px;
`;
export const AboutInfoImage = styled.img`
	background: none;
	border-radius: 4px;
	height: 300px;

	filter: grayscale(100%) brightness(0.7) opacity(95%) sepia(10%);
`;
export const AboutInfoTextWrapper = styled.div`
	/* padding: 20px; */
	width: 80%;
	@media screen and (max-width: 767px) {
		width: 90%;
	}
`;
export const AboutInfoTextH2 = styled.h2`
	font-size: 1.4rem;
	margin: 10px;
	text-align: center;
	@media screen and (max-width: 767px) {
		font-size: 1.6rem;
	}
`;
export const AboutInfoTextP = styled.p`
	font-size: 1.1rem;
	text-align: center;
	@media screen and (max-width: 767px) {
		font-size: 1.2rem;
	}
`;
export const AboutSkillsWrapper = styled.div`
	width: 30%;
	border-radius: 5px;
	box-shadow: var(--shadow);
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-bottom: 2px solid var(--contrast);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	&.appearRight {
		transition: all 1000ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(+100%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	@media screen and (max-width: 767px) {
		margin: 0px;
		width: 80%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 50%;
	}
`;
export const AboutSkill = styled.img`
	height: 65px;
	margin: 20px;
`;
export const AboutDetails = styled.div`
	min-height: 100vh;
	width: 100%;
	max-width: 1500px;
	scroll-snap-align: start;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: 767px) {
		min-height: initial;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		min-height: initial;
	}
`;

export const BasicInfoContainer = styled.div`
	width: 90%;
	max-width: 1500px;
	display: flex;
	justify-content: space-evenly;
	padding: 40px;
	border-radius: 5px;
	@media screen and (max-width: 767px) {
		width: 100%;
		flex-direction: column;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 100%;
		padding: 20px;
	}
`;
export const BasicInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 20px;
	width: 45%;
	background-color: #ffffff80;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-bottom: 2px solid var(--contrast);
	box-shadow: var(--shadow);
	border-radius: 5px;
	&.appearLeft {
		transition: all 500ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(-50%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	&.appearRight {
		transition: all 500ms ease-in;
		transform: ${(props) =>
			props.intersecting ? "translate(0px,0px)" : "translate(+50%, 0px)"};
		opacity: ${(props) => (props.intersecting ? "1" : "0")};
	}
	@media screen and (max-width: 767px) {
		width: 100%;
		margin: 10px 0px;
	}
`;
export const BasicInfoHeaderWrapper = styled.div`
	background: var(--bright);
	padding: 10px 50px;
	display: flex;
	justify-content: center;
	gap: 40px;
	align-items: center;
`;
export const BasicInfoHeader = styled.h2`
	color: var(--dark-sec);
	font-size: 1.2rem;
`;
export const BasicInfoIcon = styled.div`
	font-size: 1.5rem;
	color: var(--contrast);
`;
export const BasicInfoTextWrapper = styled.div``;
export const BasicInfoP = styled.p`
	margin: 20px 0px;
	color: var(--dark-sec);
	font-size: 0.9rem;
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1rem;
	}
`;

export const BasicInfoStrong = styled.strong`
	color: var(--dark-sec);
	font-size: 0.9rem;
`;
export const StoryContainer = styled.div`
	margin: 50px 0px;
	width: 90%;
	transition: all 2000ms ease;
	transform: ${(props) =>
		props.intersecting ? "translate(0px,0px)" : "translate(-50%, 0%)"};
	opacity: ${(props) => (props.intersecting ? "1" : "0")};

	@media screen and (max-width: 767px) {
		width: 95%;
		margin-bottom: 100px;
	}
	/* @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		margin-bottom: 100px;
	} */
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-bottom: 2px solid var(--contrast);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 5px;
`;
export const StoryHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 40px;
	justify-content: space-between;
	padding: 10px 20px;
	background: var(--dark);
	margin-top: 20px;
	border-radius: 5px;
	box-shadow: var(--shadow);
	@media screen and (max-width: 767px) {
		width: 95%;
	}
`;
export const StoryHeader = styled.h2`
	font-size: 1.2rem;
	@media screen and (max-width: 767px) {
		font-size: 1rem;
	}
`;
export const StoryIcon = styled.div`
	font-size: 1.5rem;
	color: var(--contrast);
`;
