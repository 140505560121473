import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/About";
import Projects from "./screens/Projects";
import Contact from "./screens/Contact";
import { TouchContextProvider } from "./Contexts/TouchContextProvider";
function App() {
	return (
		<TouchContextProvider>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<Home />}></Route>
					<Route exact path="/about" element={<About />}></Route>
					<Route exact path="/projects" element={<Projects />}></Route>
				</Routes>
			</BrowserRouter>
		</TouchContextProvider>
	);
}

export default App;
