import Particles from "../../Components/Projects/Particles";
import { ProjectJump } from "../../Components/Projects/ProjectJump/ProjectJump";
import ProjectX from "../../Components/Projects/ProjectX";
import ProjectZ from "../../Components/Projects/ProjectZ";
import WordleHelper from "../../Components/Projects/Wordle-Helper";

export const projects = [
	{
		thumbnail: require("../../images/project-jump.png").default,
		id: "projectJump",
		year: "2018",
		month: "Mai",
		name: "Project Jump",
		text1: (
			<p>A simple arcade-like skill game based on javascript and the canvas-api.</p>
		),
		text2: (
			<p>
				I started my internship at Intershop Communications AG in April 2018. It
				was there that I first came into contact with topics such as web
				development and e-commerce in general. Since my knowledge in the context
				of development was almost non-existent at that time, I decided to further
				my education in this area. <br />
				Since I have always enjoyed playing computer games, it seemed to make the
				most sense to me to learn how to program a small game. Therefore i took a
				few Udemy courses in order to learn how to develop a simple game with
				javascript. Afterwards I tried to improve my skills by creating my first
				own project. To do this, I used the skills I learned in the courses to
				create a jump and run like arcade game with simple game physics and an
				easy to understand objective.
			</p>
		),
		hardSkills: ["HTML", "HTML-Canvas", "CSS", "JS ES6"],
		projectShowCaseContent: <ProjectJump />,
	},
	{
		thumbnail: require("../../images/particle-effect.png").default,
		id: "particles",
		year: 2021,
		month: "Mai",
		name: "Particles Effect",
		text1: <p>A cool particles effect based on the canvas api. </p>,
		text2: (
			<p>
				In april 2021, i successfully completed my Master of Science in business
				administration. Until then, i fortunately had the opportunity to greatly
				expand my knowledge in the context of e-commerce and web development
				through continuous work at Intershop Communications AG as a student
				assistant in requirements engineering. <br />
				Following on from this, I wanted to further expand my know-how in the
				context of development. When I saw a website with a particles effect one
				day, I thought that with the knowledge from my mini-project in 2016 and
				the know-how from my student work, I should be able to recreate this
				particles effect using the canvas-api and javascript. No sooner said than
				done i created this particles effect.
			</p>
		),
		hardSkills: ["HTML", "HTML-Canvas", "JS ES6", "OOP with JS", "Classes in JS"],
		// projectShowCaseContent: <CustomIFrame src="https://swift-ride.herokuapp.com/" />,
		projectShowCaseContent: <Particles />,
	},
	{
		thumbnail: require("../../images/project-z.png").default,
		id: "projectZ",
		year: 2021,
		month: "Juli",
		name: "Project Z",
		text1: (
			<p>
				A arcade-like 2D-survival-shooter with upgrade mechanics. Also based on
				the canvas-api.
			</p>
		),
		text2: (
			<p>
				After finshing the implementation of the particles effect, I had a good
				baseline knowledge to create what I actually already wanted to create back
				in 2018. A survival mini-game with zombies. I utilized the javascript
				class system as well as the OOP features to create ProjectZ. This further
				strenghtend my development skills and paved the way for my next project...
			</p>
		),
		hardSkills: ["HTML-Canvas", "OOP with JS", "Classes in JS"],
		projectShowCaseContent: <ProjectZ />,
	},
	{
		thumbnail: require("../../images/logo-dark.png").default,
		id: "PerfectFit",
		year: "2021",
		month: "August",
		name: "PerfectFit: Bike",
		text1: (
			<p>
				A comparison platform for bikes, based on the MERN-Stack. <br />{" "}
				Keyfeatures are a bike-finder, which makes it possible to find fitting
				bikes by answering a set of questions. Also product lists, distinctive
				filter functions as well as the possiblitiy to compare bikes are included.
			</p>
		),
		text2: (
			<p>
				During my work at Intershop Communications AG, I had a lot to do with the
				Intershop CMS in terms of writing feature concepts for our customers.
				Unfortunately, I didn't have the opportunity to get a full understanding
				of it (from a developer's point of view). <br />
				For this reason, my next project should compensate for this shortcoming.
				After finding the initial idea of a bicycle comparison platform with a
				bike-finder feature and some further research, I decidied to create a
				fullstack-application. Hence I've choosen to work with the MERN stack
				(MongoDB as database, Express.js for the backend, React.js for the
				frontend and Node.js as runtime environment) to realise this project.
				<br />
				After I had familiarised myself with the work with the MERN-stack using
				youtube videos, I conceptually framed the initial features for the
				application and began with the implementation. <br />
				As in all previous projects, my focus was on solving all problems by
				myself as best I could, without relying on ready-made solutions from
				others. In this way, I was able to learn even more about the MERN-stack.
			</p>
		),
		hardSkills: [
			"React.js",
			"Styled-Components",
			"Redux",
			"Express.js",
			"Node.js",
			"MongoDB",
			"Advanced CSS",
			"JSX",
			"Axios",
			"API-Concept",
			"JS up to ES10",
		],
		// projectShowCaseContent: <CustomIFrame src="https://swift-ride.herokuapp.com/" />,
		projectShowCaseContent: <ProjectX />,
	},
	{
		thumbnail: require("../../images/wordle-helper.png").default,
		id: "wordle-helper",
		year: "2022",
		month: "January",
		name: "Wordle-Helper",
		text1: (
			<p>
				A helping utility to solve the hyped puzzle-game{" "}
				<a target="_blank" href="https://www.powerlanguage.co.uk/wordle/">
					Worlde
				</a>{" "}
				quicker and easier.
			</p>
		),
		text2: (
			<p>
				After playing the online word puzzle game "Wordle" with friends and family
				for some time, I had the idea to write a script that could help me solve
				those puzzles easier and quicker. To make this tool easier to use and more
				accessible to others, I also decided to put it online as a small website.
				Although this was only a small one-day project, I was motivated to make
				the usage and overall UI as smooth and simple as possible.
			</p>
		),
		hardSkills: ["Basic HTML", "JS ES7", "Regex in JS", "Basic CSS"],
		// projectShowCaseContent: <CustomIFrame src="https://swift-ride.herokuapp.com/" />,
		projectShowCaseContent: <WordleHelper />,
	},
];
