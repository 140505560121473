import React, { useRef, useState } from "react";
import {
	NavButtonWrapper,
	NavLI,
	NavLink,
	NavSection,
	NavDiv1,
	NavDiv2,
	NavDiv3,
	NavUL,
	NavWrapper,
	MailLink,
} from "./NavigationElements";

export const Navigation = () => {
	const [toggleNav, setToggleNav] = useState(false);
	const mailLink = useRef();
	function UnCryptMailto(s) {
		var n = 0;
		var r = "";
		for (var i = 0; i < s.length; i++) {
			n = s.charCodeAt(i);
			if (n >= 8364) {
				n = 128;
			}
			r += String.fromCharCode(n - 1);
		}
		return r + "?subject=We want to get to know you, Steven!";
	}

	function linkTo_UnCryptMailto(s) {
		mailLink.current.href = UnCryptMailto(s);
	}

	return (
		<NavWrapper active={toggleNav}>
			<NavButtonWrapper active={toggleNav} onClick={() => setToggleNav(!toggleNav)}>
				<NavDiv1 active={toggleNav}></NavDiv1>
				<NavDiv2 active={toggleNav}></NavDiv2>
				<NavDiv3 active={toggleNav}></NavDiv3>
			</NavButtonWrapper>
			<NavSection active={toggleNav}>
				<NavUL>
					<NavLI active={toggleNav} delay={"0s"}>
						<NavLink to="/">HOME</NavLink>
					</NavLI>
					<NavLI active={toggleNav} delay={"0.2s"}>
						<NavLink to="/about">ABOUT</NavLink>
					</NavLI>
					<NavLI active={toggleNav} delay={"0.4s"}>
						<NavLink to="/projects">PROJECTS</NavLink>
					</NavLI>
					<NavLI active={toggleNav} delay={"0.6s"}>
						<MailLink
							ref={mailLink}
							onClick={() =>
								linkTo_UnCryptMailto("nbjmup;tdivm{tufwfo:5Ahny/ef")
							}>
							CONTACT
						</MailLink>
					</NavLI>
				</NavUL>
			</NavSection>
		</NavWrapper>
	);
};
