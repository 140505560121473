import React, { createContext, useContext, useEffect, useState } from "react";

export const TouchContext = createContext(false);
TouchContext.displayName = "TouchContext";

export const TouchContextProvider = ({ children }) => {
	const [touch, setTouch] = useState(false);
	// ! According to https://deviceatlas.com/blog/mobile-browser-user-agent-strings
	const TouchDevices = [
		"iphone",
		"linux; android",
		"linux; u;",
		"j2me/midp",
		"compatible; msie",
		"windows phone",
		"bb10;",
		"ipad;",
	];

	useEffect(() => {
		setTouch(isTouchDevice());
	}, []);

	function isTouchDevice() {
		return (
			("ontouchstart" in window ||
				navigator.maxTouchPoints > 0 ||
				navigator.msMaxTouchPoints > 0) &&
			isTouchUA()
		);
	}
	const isTouchUA = () => {
		let touchUA = false;
		TouchDevices.forEach((string) => {
			const ua = window.navigator.userAgent.toLowerCase();
			if (ua.includes(string)) {
				touchUA = true;
			}
		});
		return touchUA;
	};
	return <TouchContext.Provider value={touch}>{children}</TouchContext.Provider>;
};
