import { useEffect, useRef, useState } from "react";

export const useIntersect = ({ root = null, rootMargin, threshold = 0 }) => {
	const [entry, setEntry] = useState({});
	const [node, setNode] = useState(null);
	const [intersecting, setIntercecting] = useState();
	const observer = useRef(
		new window.IntersectionObserver(([entry]) => setEntry(entry), {
			root,
			rootMargin,
			threshold,
		})
	);
	useEffect(() => {
		setIntercecting(entry.isIntersecting);
	}, [entry.isIntersecting]);

	useEffect(() => {
		const { current: currentObserver } = observer;
		if (node) currentObserver.observe(node);
		return () => currentObserver.disconnect();
	}, [node]);
	return [setNode, entry, intersecting];
};
