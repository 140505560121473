import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

export const ProjectContainer = styled.div`
	z-index: ${(props) => (props.state > 0 ? 10 : 4)};
	width: 100vw;
	display: flex;
	max-height: 100vh;
	justify-content: center;
	position: relative;
	scroll-snap-align: start;
	@media screen and (max-width: 767px) {
		max-height: initial;
		scroll-snap-align: ${(props) => (props.state > 0 ? "start" : "none")};
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		max-height: initial;
		min-height: 100vh;
		scroll-snap-align: ${(props) => (props.state > 0 ? "start" : "none")};
	}
`;
export const ProjectWrapper = styled.div`
	width: 100vw;
	max-width: 1100px;
	height: 100vh;
	padding: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	@media screen and (max-width: 767px) {
		height: auto;

		padding: 3% 5%;
	}

	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		height: auto;
	}
`;
export const Blur = styled.div`
	display: ${(props) => (props.sidebarState > 0 ? "block" : "none")};
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.7);
`;

export const ProjectSidebar = styled.div`
	transition: left 0.5s ease-in;
	animation-delay: sidebarOpen 1s;
	position: absolute;
	top: 5vh;
	top: ${(props) => (props.sidebarState > 0 ? "5vh" : "calc(50vh - 100px)")};

	left: ${(props) =>
		props.sidebarState === 0
			? "calc(-140vw + 60px)"
			: props.sidebarState === 1
			? "calc(-100vw + 60px)"
			: "0"};
	z-index: 10;
	height: 90vh;
	height: ${(props) => (props.sidebarState > 0 ? "90vh" : "200px")};

	display: flex;
	border-radius: 0px 5px 5px 0px;

	background: var(--gradient-dark);
	animation-name: ${(props) =>
		props.sidebarState > 0 ? "sidebarOpen" : "sidebarClose"};
	animation-duration: 1.5s;

	@keyframes sidebarOpen {
		from {
			top: calc(50vh - 100px);
			height: 200px;
			left: calc(-140vw + 60px);
		}
		25% {
			top: 5vh;
			height: 90vh;
			left: calc(-140vw + 60px);
		}
		to {
			left: calc(-100vw + 60px);
		}
	}
	@keyframes sidebarClose {
		from {
			top: 5vh;
			height: 90vh;
			left: calc(-100vw + 60px);
		}
		75% {
			top: 5vh;
			height: 90vh;
		}
		to {
			top: calc(50vh - 100px);
			height: 200px;
			left: calc(-140vw + 60px);
		}
	}

	// !!Test
	@media screen and (max-width: 767px) {
		left: ${(props) =>
			props.sidebarState === 0
				? "calc(-200vw + 70px)"
				: props.sidebarState === 1
				? "calc(-100vw + 35px  )"
				: "0"};
		top: ${(props) => (props.sidebarState > 0 ? "0" : "calc(50vh - 50px)")};
		height: ${(props) => (props.sidebarState > 0 ? "100vh" : "100px")};

		animation-name: ${(props) =>
			props.sidebarState > 0 ? "sidebarOpen" : "sidebarClose"};
		animation-duration: 1.5s;
		@keyframes sidebarOpen {
			from {
				top: calc(50vh - 50px);
				height: 100px;
				left: calc(-200vw + 70px);
			}
			25% {
				top: 0px;
				height: 100vh;
				left: calc(-200vw + 70px);
			}
			to {
				left: calc(-100vw + 35px);
			}
		}
		@keyframes sidebarClose {
			from {
				top: 0px;
				height: 100vh;
				left: calc(-100vw + 35px);
			}
			75% {
				top: 0px;
				height: 100vh;
			}
			to {
				top: calc(50vh - 50px);
				height: 100px;
				left: calc(-200vw + 70px);
			}
		}
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		left: ${(props) =>
			props.sidebarState === 0
				? "calc(-200vw + 112px)"
				: props.sidebarState === 1
				? "calc(-100vw + 50px  )"
				: "0"};
		@keyframes sidebarOpen {
			from {
				top: calc(50vh - 100px);
				height: 200px;
				left: calc(-200vw + 112px);
			}
			25% {
				top: 5vh;
				height: 90vh;
				left: calc(-200vw + 112px);
			}
			to {
				left: calc(-100vw + 50px);
			}
		}
		@keyframes sidebarClose {
			from {
				top: 5vh;
				height: 90vh;
				left: calc(-100vw + 50px);
			}
			75% {
				top: 5vh;
				height: 90vh;
			}
			to {
				top: calc(50vh - 100px);
				height: 200px;
				left: calc(-200vw + 112px);
			}
		}
	}
`;
export const ProjectShowCaseContainer = styled.div`
	width: calc(100vw - 60px);
	@media screen and (max-width: 767px) {
		width: calc(100vw - 35px);
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: calc(100vw - 50px);
	}
`;
export const ProjectHardSkillsContainer = styled.div`
	width: calc(40vw - 60px);
	display: flex;
	flex-direction: column;
	padding: 1% 0px;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 767px) {
		width: calc(100vw - 65px);
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: calc(100vw - 112px);
	}
`;

export const SideBarButton = styled.button`
	background-color: #ffffff80;
	backdrop-filter: blur(12px);
	display: flex;
	justify-content: center;
	align-items: center;
	--webkit-backdrop-filter: blur(12px);
	height: 100%;
	width: 60px;
	border: none;
	box-shadow: var(--shadow2);
	border-radius: 0px 5px 5px 0px;
	@media screen and (max-width: 767px) {
		width: 30px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 50px;
	}
`;
export const SideBarButtonArrow0 = styled(IoIosArrowForward)`
	width: 20px;
	height: 80vh;
	color: white;
	transition: all 0.5s ease-in-out;
	transform: ${(props) => (props.sidebarState > 0 ? "rotate(180deg)" : "")};
`;

export const SideBarButtonArrow1 = styled(IoIosArrowForward)`
	width: 20px;
	height: 80vh;
	color: white;

	transition: all 0.5s ease-in-out;
	transform: ${(props) => (props.sidebarState > 1 ? "rotate(180deg)" : "")};
`;

export const ProjectDetailWrapper = styled.div`
	width: 100%;
	height: 45vh;
	display: flex;
	@media screen and (max-width: 767px) {
		margin: 30px 0px;
		flex-wrap: wrap;
		height: initial;
		min-height: 50vh;
		width: 100%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 90%;
		height: initial;
		align-items: flex-start;
	}
`;

export const ProjectThumbnailWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 2s ease;
	opacity: ${(props) => (props.intersecting ? "1" : "0")};
	@media screen and (max-width: 767px) {
		padding: 0px;
		width: 100%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		height: 100%;
	}
`;
export const ProjectThumbnail = styled.img`
	max-width: 300px;
	max-height: 300px;
	background-color: var(--bright);
	box-shadow: var(--shadow);
	@media screen and (max-width: 767px) {
		max-width: 150px;
		max-height: 150px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		max-width: 150px;
		max-height: 150px;
	}
`;
export const ProjectDetailDescriptionWrapper = styled.div`
	flex: 1;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: ${(props) => (props.intersecting ? "1" : "0")};
	transition: all 1s ease;
	transform: ${(props) =>
		props.intersecting ? "translate(0px, 0px)" : "translate(+50%,0%)"};

	@media screen and (max-width: 767px) {
		flex: auto;
		padding: 0px;
		justify-content: flex-start;
		width: 100%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		padding: 0;
	}
`;
export const ProjectHeading = styled.h1`
	width: 80%;
	text-align: center;
	padding: 10px 20px;
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-radius: 5px;
	box-shadow: var(--shadow);
	margin-bottom: 40px;
	border-bottom: 2px solid var(--contrast);
	@media screen and (max-width: 767px) {
		font-size: 1rem;
		margin-bottom: 40px;
		margin-top: 20px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		width: 90%;
	}
`;
export const HeaderDate = styled.p`
	font-size: 0.8rem;
	font-weight: 400;
	text-align: center;
`;
export const ProjectDetailDescription = styled.div`
	@media screen and (max-width: 767px) {
		font-size: 1rem;
		padding: 0px 20px;
		text-align: justify;
		width: 100%;
	}
`;
export const ProjectStoryWrapper = styled.div`
	padding: 40px;
	transition: all 1s ease;
	transform: ${(props) =>
		props.intersecting ? "translate(0px, 0px)" : "translate(+50%,0%)"};
	@media screen and (max-width: 767px) {
		flex: auto;
		padding: 0px 20px;
		margin-top: 10px;
	}
`;
export const ProjectStoryHeading = styled.h1`
	@media screen and (max-width: 767px) {
		font-size: 1.4rem;
	}
`;
export const ProjectStory = styled.div``;

export const CustomHR = styled.hr`
	border: 2px solid var(--contrast);
	border-radius: 2px;
	box-shadow: var(--shadow2);
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
`;
