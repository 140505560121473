import React from "react";
import {
	HardSkill,
	HardSkillCheck,
	HardSkillIntroduction,
	HardSkillList,
	HardSkillListElement,
	HardSkillsHeader,
} from "./ProjectHardSkillsElements";

export const ProjectHardSkillsComponent = (props) => {
	return (
		<>
			<HardSkillsHeader>Hard-Skills</HardSkillsHeader>
			<HardSkillIntroduction>What i have learned:</HardSkillIntroduction>
			<HardSkillList>
				{props.hardSkills.map((skill, idx) => (
					<HardSkillListElement key={idx}>
						<HardSkill>{skill}</HardSkill>
						<HardSkillCheck />
					</HardSkillListElement>
				))}
			</HardSkillList>
		</>
	);
};
