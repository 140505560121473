import React, { useEffect, useRef, useState } from "react";
import {
	AboutStoryWrapper,
	ArrowLeft,
	ArrowRight,
	Dot,
	StoryDots,
	StoryNav,
	StoryTextBox,
	StoryTextBoxHeader,
	StoryTextBoxP,
	StoryTextBoxWrapper,
} from "./AboutStoryElements";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { storyPoints } from "./data";
import { useIntersect } from "../../CustomHooks/useIntersect";
export const AboutStoryComponent = () => {
	const [aboutWrapper, entry, aboutWrapperIntersect] = useIntersect({
		threshold: 0,
	});
	const [storyState, setStoryState] = useState(0);
	const [ready, setReady] = useState(false);
	const wrapper = useRef();
	useEffect(() => {
		setReady(true);
	}, []);
	const handleState = (direction) => {
		switch (direction) {
			case "forward":
				if (storyState < storyPoints.length - 1) {
					setStoryState(storyState + 1);
				}
				break;
			case "backward":
				if (storyState > 0) {
					setStoryState(storyState - 1);
				}
				break;
			default:
				return;
		}
	};
	return (
		<AboutStoryWrapper ref={aboutWrapper} intersecting={aboutWrapperIntersect}>
			<StoryTextBoxWrapper ref={wrapper} onClick={() => handleState("forward")}>
				{ready
					? storyPoints.map((story, idx) => (
							<StoryTextBox
								key={idx}
								active={idx === storyState}
								storyState={storyState}
								parentWidth={wrapper.current.offsetWidth}
								boxCount={storyPoints.length}>
								<StoryTextBoxHeader active={idx === storyState}>
									{story.header}{" "}
								</StoryTextBoxHeader>
								<StoryTextBoxP>{story.text} </StoryTextBoxP>
							</StoryTextBox>
					  ))
					: ""}
			</StoryTextBoxWrapper>
			<StoryNav>
				<ArrowLeft
					storyState={storyState}
					onClick={() => handleState("backward")}>
					<IoIosArrowBack />
				</ArrowLeft>
				<StoryDots>
					{storyPoints.map((story, idx) => (
						<Dot
							onClick={() => setStoryState(idx)}
							key={idx}
							className={idx === storyState ? "active" : ""}
						/>
					))}
				</StoryDots>
				<ArrowRight
					storyState={storyState}
					amount={storyPoints.length - 1}
					onClick={() => handleState("forward")}>
					<IoIosArrowForward />
				</ArrowRight>
			</StoryNav>
		</AboutStoryWrapper>
	);
};
