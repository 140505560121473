import React from "react";
import { Button, ButtonIcon, ButtonWrapper } from "./ScrollToTopBTNElements";

export const ScrollToTopBTN = (props) => {
	return (
		<ButtonWrapper active={props.scrollPos >= props.windowHeight}>
			<Button href="#ProjectLanding">
				<ButtonIcon />
			</Button>
		</ButtonWrapper>
	);
};
