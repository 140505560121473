import React, { useEffect, useRef, useState } from "react";
import {
	CloseButton,
	IntroBlur,
	IntroWindow,
	IntroWindowButton,
	IntroWindowHeadline,
	IntroWindowText,
	IntroWrapper,
	ProgressStatus,
	ProjectsContainer,
} from "./ProjectsElements";
import { projects } from "./projectData";
import ProjectComponent from "../../Components/ProjectComponent";
import ProjectNav from "../../Components/ProjectNav";
import ProjectLandingComponent from "../../Components/ProjectLanding";
import ScrollToTopBTN from "../../Components/ScrollToTopButton";

export const Projects = () => {
	//explain info
	const container = useRef(null);
	const [explained, setExplained] = useState(false);
	const [introState, setIntroState] = useState(0);
	const [forceState, setForceState] = useState(0);
	const [introContent, setIntroContent] = useState({
		headline: "Welcome",
		text: "To my project showcase. Let this little introduction guide you through the sections.",
		buttonLabel: "Alright!",
	});

	const [scrollPos, setScrollPos] = useState(0);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	useEffect(() => {
		setExplained(sessionStorage.getItem("explained") ?? false);
	}, []);

	useEffect(() => {
		setWindowHeight(window.innerHeight);
	}, [window.innerHeight]);
	const handleScroll = (scrollDistance) => {
		setScrollPos(scrollDistance);
	};

	useEffect(() => {
		switch (introState) {
			case 1:
				return;
			default:
				return;
		}
	}, [introState]);
	//explain

	useEffect(() => {
		// console.log(scrollPos, windowHeight);
		if (!explained && scrollPos >= windowHeight && introState < 1) {
			setIntroState(1);
		}
	}, [scrollPos]);

	const handleIntroClose = () => {
		setExplained(true);
		sessionStorage.setItem("explained", "true");
	};
	const handleIntroProceed = (iState) => {
		switch (iState) {
			case 1:
				container.current.scrollTo({
					left: 0,
					top: windowHeight,
					behavior: "smooth",
				});
				setIntroState(introState + 1);
				setIntroContent({
					headline: "Overview!",
					text: "First you will see the project overview. Not only the name, date and a short description is presented here, but also the contextual story around each project.",
					buttonLabel: "Interesting",
				});
				break;
			case 2:
				setForceState(1);
				setIntroState(introState + 1);
				setIntroContent({
					headline: "Hard Skills",
					text: "Next you can open up the sidebar. The first thing you will see is an short overview about the skills i aquired/ used by developing the respective project.",
					buttonLabel: "Okay",
				});
				break;
			case 3:
				setForceState(2);
				setIntroState(introState + 1);
				setIntroContent({
					headline: "Showcase",
					text: (
						<>
							Beside that, if you open up the sidebar once more, you will be
							see an actual demo of the project. Some projects can only be
							really observerd on desktop computers (e.g. the games).
							<br />
							<strong>Finally, have fun exploring my projects!</strong>
						</>
					),
					buttonLabel: "Thank You!",
				});
				break;
			case 4:
				setForceState(0);
				setExplained(true);
				sessionStorage.setItem("explained", "true");

				break;
			default:
				return;
		}
	};
	return (
		<ProjectsContainer
			ref={container}
			onScroll={(e) => handleScroll(e.target.scrollTop)}
			id="projects-container">
			<ScrollToTopBTN windowHeight={windowHeight} scrollPos={scrollPos} />
			<ProjectNav
				windowHeight={windowHeight}
				scrollPos={scrollPos}
				projects={projects}
			/>
			<ProjectLandingComponent projects={projects} />
			{introState > 0 && explained === false ? (
				<IntroWrapper>
					<IntroBlur introState={introState}></IntroBlur>
					<IntroWindow introState={introState}>
						<CloseButton onClick={handleIntroClose} />
						<ProgressStatus>{introState}/4</ProgressStatus>
						<IntroWindowHeadline>{introContent.headline}</IntroWindowHeadline>
						<IntroWindowText>{introContent.text}</IntroWindowText>
						<IntroWindowButton onClick={() => handleIntroProceed(introState)}>
							{introContent.buttonLabel}
						</IntroWindowButton>
					</IntroWindow>
				</IntroWrapper>
			) : (
				""
			)}
			{projects.map((project, idx) => (
				<ProjectComponent
					forceState={idx === 0 ? forceState : ""}
					id={project.id}
					key={idx}
					project={project}
				/>
			))}
		</ProjectsContainer>
	);
};
