import styled from "styled-components";
import { VscDebugBreakpointLog } from "react-icons/vsc";
export const AboutStoryWrapper = styled.div`
	width: 90%;
	margin: 20px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;

	@media screen and (max-width: 480px) {
		width: 100%;
		justify-content: center;
	}
`;
export const StoryTextBoxWrapper = styled.div`
	width: 100%;
	position: relative;
	height: 200px;
	transform: translateX(0);
	cursor: pointer;
	@media screen and (max-width: 480px) {
		height: 300px;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		height: 250px;
	}
`;
export const StoryTextBox = styled.div`
	position: absolute;
	transition: all 0.8s cubic-bezier(0.42, 0, 0.55, 1.13);
	left: ${(props) =>
		props.storyState * Math.round((props.parentWidth - 330) / props.boxCount)}px;
	opacity: ${(props) => (props.active ? "1" : "0")};
	z-index: ${(props) => (props.active ? "2" : "0")};
	transform: ${(props) => (props.active ? "scale(1)" : "scale(0.7)rotateX(180deg)")};
	box-shadow: var(--shadow);
	border-radius: 10px;
	height: 200px;
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--bright);
	padding: 10px;
	@media screen and (max-width: 480px) {
		height: 300px;
		width: 100%;
		position: absolute;
		left: 0;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		height: 250px;
	}
`;
export const StoryTextBoxHeader = styled.h2`
	color: var(--dark-sec-o);
	font-size: 1rem;
	margin-bottom: 5px;
	transition: border-bottom 1.2s ease;
	position: relative;
	&:after {
		content: "";
		position: absolute;
		transition: all 2s ease;

		left: ${(props) => (props.active ? "10%" : "50%")};
		bottom: -3px;
		height: 2px;
		width: ${(props) => (props.active ? "80%" : "0%")};
		border-bottom: 2px solid var(--contrast);
	}
	@media screen and (max-width: 480px) {
		font-size: 1.2rem;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.4rem;
	}
`;
export const StoryTextBoxP = styled.p`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-items: center;
	color: var(--dark-sec-o);
	font-size: 0.8rem;
	text-align: center;
	line-height: 20px;
	@media screen and (max-width: 480px) {
		font-size: 1.1rem;
		justify-content: flex-start;
		margin-top: 5%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.1rem;
		justify-content: flex-start;
		margin-top: 5px;
	}
`;
export const StoryTextBoxLink = styled.a`
	cursor: pointer;
	display: block;
	text-align: center;
	color: var(--text-bright);
	text-decoration: none;
	margin: 4px 0px;
	background: var(--dark-sec-o);
	padding: 4px;
	border-radius: 4px;
	width: 90%;
`;
export const StoryNav = styled.div`
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	@media screen and (max-width: 480px) {
		justify-content: space-evenly;
	}
`;
export const ArrowLeft = styled.div`
	color: var(--text-bright);
	font-size: 1.5rem;
	cursor: pointer;
	display: ${(props) => (props.storyState === 0 ? "none" : "block")};
`;
export const StoryDots = styled.div`
	margin: 0px 10px;
	@media screen and (max-width: 480px) {
		display: none;
	}
`;
export const Dot = styled(VscDebugBreakpointLog)`
	margin: 10px 20px;
	color: var(--text-bright);
	cursor: pointer;
	&.active {
		color: var(--contrast);
		transform: scale(1.2);
		font-size: 1.3rem;
	}
`;
export const ArrowRight = styled.div`
	color: var(--text-bright);
	font-size: 1.5rem;
	cursor: pointer;
	display: ${(props) => (props.storyState === props.amount ? "none" : "block")};
`;
