import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ProjectShowCaseWrapper } from "../ProjectJump/ProjectJumpElements";
import { ParticlesEffectCanvas } from "./ParticlesElements";
export const Particles = () => {
	const wrapper = useRef();
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [ready, setReady] = useState(false);
	const id = useRef(null);
	var canvas, ctx;
	useLayoutEffect(() => {
		setHeight(wrapper.current.offsetHeight);
		setWidth(wrapper.current.offsetWidth);
		setReady(true);
	}, []);

	useEffect(() => {
		if (ready) {
			canvas = document.getElementById("effectScreen");
			ctx = canvas.getContext("2d");
			canvas.width = width;
			canvas.height = height;
			class Ball {
				constructor(x, y, r, xSpeed, ySpeed, color) {
					this.x = x;
					this.y = y;
					this.r = r;
					this.xSpeed = xSpeed;
					this.ySpeed = ySpeed;
					this.color = color;
					this.inScreen = true;
				}

				draw(ctx) {
					ctx.beginPath();
					ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
					ctx.strokeStyle = this.color;
					ctx.stroke();
					ctx.fillStyle = this.color;
					ctx.fill();
				}

				move() {
					if (this.inScreen === true) {
						this.x = this.x + this.xSpeed;
						this.y = this.y + this.ySpeed;
					} else {
						let j = Math.random();
						if (j < 0.25) {
							this.x = 0 + 2 * this.r;
							this.y =
								2 * this.r + Math.random() * canvas.height - 2 * this.r;
							this.xSpeed = 1;
							this.ySpeed = getSpeed();
						} else if (j < 0.5) {
							this.x = canvas.width - 2 * this.r;
							this.y =
								2 * this.r + Math.random() * canvas.height - 2 * this.r;
							this.xSpeed = -1;
							this.ySpeed = getSpeed();
						} else if (j < 0.75) {
							this.x = Math.random() * canvas.width;
							this.y = 0;
							this.xSpeed = getSpeed();
							this.ySpeed = 1;
						} else {
							this.x = Math.random() * canvas.width;
							this.y = canvas.height;
							this.xSpeed = getSpeed();
							this.ySpeed = -1;
						}
						this.inScreen = true;
					}
				}

				exitScreen() {
					if (
						this.x < -40 - 2 * this.r ||
						this.x > canvas.width + 40 ||
						this.y < -40 - 2 * this.r ||
						this.y > canvas.height + 40
					) {
						this.inScreen = false;
					}
				}
			}
			var mouseX = null;
			var mouseY = null;
			var mouseDown = false;
			// EVENT LISTENERS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			window.addEventListener("mousemove", function (e) {
				var rect = e.target.getBoundingClientRect();
				mouseX = e.clientX - rect.left;
				mouseY = e.clientY - rect.top;
			});

			window.addEventListener("mousedown", function (e) {
				mouseDown = true;
			});
			window.addEventListener("mouseup", function (e) {
				mouseDown = false;
			});

			var ballArray = [];

			const drawing = new Image();

			drawing.src =
				"https://images.pexels.com/photos/176851/pexels-photo-176851.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=1920&w=1280";

			intBalls(200);

			// EFFECT LOOOP !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

			function effectLoop() {
				spawnBalls();
				moveBalls();
				checkExitBalls();
				draw();
				id.current = window.requestAnimationFrame(effectLoop);
			}

			// DRAWING !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

			function draw() {
				clearScreen();
				drawLine(ballArray, 50, 100, 150);
				drawLineToMouse(ballArray, mouseX, mouseY, 50, 100, 200);
				drawBalls(ballArray.length);
			}

			// FUNCTIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

			function drawBalls(amount) {
				for (var x = 0; x < amount; x++) {
					ballArray[x].draw(ctx);
				}
			}

			function moveBalls() {
				for (var x = 0; x < ballArray.length; x++) {
					ballArray[x].move();
				}
			}
			function checkExitBalls() {
				for (var x = 0; x < ballArray.length; x++) {
					ballArray[x].exitScreen();
				}
			}

			function getSpeed() {
				if (Math.random() < 0.5) {
					return 0.25 + Math.random() * -1;
				} else {
					return 0.25 + Math.random();
				}
			}

			function intBalls(amount) {
				for (var x = 0; x < amount; x++) {
					ballArray[x] = new Ball(
						Math.random() * canvas.width - 10,
						Math.random() * canvas.height - 10,
						1 + Math.random() * 3,
						getSpeed(),
						getSpeed(),
						"rgba(245,245,245,0.5)"
					);
				}
			}

			function clearScreen() {
				// ctx.fillStyle = "#202020";
				ctx.drawImage(drawing, 0, 0, canvas.width, canvas.height);
				ctx.fillStyle = "rgba(0,0,0,0.2)";
				ctx.fillRect(0, 0, canvas.width, canvas.height);
			}

			function drawLine(array, dist1, dist2, dist3) {
				for (var x = 0; x < array.length; x++) {
					for (var y = 0; y < array.length; y++) {
						if (
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) <= dist1 &&
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) > 0
						) {
							ctx.beginPath();

							ctx.strokeStyle = "rgba(245,245,245,0.2)";
							ctx.moveTo(array[x].x, array[x].y);
							ctx.lineTo(array[y].x, array[y].y);
							ctx.stroke();
						} else if (
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) <= dist2 &&
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) > 0
						) {
							ctx.beginPath();
							ctx.strokeStyle = "rgba(245,245,245,0.1)";
							ctx.moveTo(array[x].x, array[x].y);
							ctx.lineTo(array[y].x, array[y].y);
							ctx.stroke();
						} else if (
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) <= dist3 &&
							Math.sqrt(
								Math.pow(array[x].x - array[y].x, 2) +
									Math.pow(array[x].y - array[y].y, 2)
							) > 0
						) {
							ctx.beginPath();
							ctx.strokeStyle = "rgba(245,245,245,0.05)";
							ctx.moveTo(array[x].x, array[x].y);
							ctx.lineTo(array[y].x, array[y].y);
							ctx.stroke();
						}
					}
				}
			}

			function spawnBalls() {
				if (mouseDown === true) {
					for (var i = 0; i < 4; i++) {
						ballArray.push(
							new Ball(
								mouseX,
								mouseY,
								1 + Math.random() * 3,
								getSpeed(),
								getSpeed(),
								"rgba(245,245,245,0.5)"
							)
						);
					}
					mouseDown = false;
				}
			}

			function drawLineToMouse(array, mouseX, mouseY, dist1, dist2, dist3) {
				for (var x = 0; x < array.length; x++) {
					if (
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) <= dist1 &&
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) > 0
					) {
						ctx.beginPath();
						ctx.strokeStyle = "rgba(245,245,245,0.8)";
						ctx.moveTo(array[x].x, array[x].y);
						ctx.lineTo(mouseX, mouseY);
						ctx.stroke();
					} else if (
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) <= dist2 &&
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) > 0
					) {
						ctx.beginPath();
						ctx.strokeStyle = "rgba(245,245,245,0.6)";
						ctx.moveTo(array[x].x, array[x].y);
						ctx.lineTo(mouseX, mouseY);
						ctx.stroke();
					} else if (
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) <= dist3 &&
						Math.sqrt(
							Math.pow(array[x].x - mouseX, 2) +
								Math.pow(array[x].y - mouseY, 2)
						) > 0
					) {
						ctx.beginPath();
						ctx.strokeStyle = "rgba(245,245,245,0.1)";
						ctx.moveTo(array[x].x, array[x].y);
						ctx.lineTo(mouseX, mouseY);
						ctx.stroke();
					}
				}
			}
			effectLoop();
		}
	}, [ready]);

	useEffect(() => {
		return () => {
			window.cancelAnimationFrame(id.current);
			setReady(false);
		};
	}, []);

	return (
		<ProjectShowCaseWrapper className="particles" ref={wrapper}>
			<ParticlesEffectCanvas id="effectScreen"></ParticlesEffectCanvas>
		</ProjectShowCaseWrapper>
	);
};
