import styled from "styled-components";
import { GrSquare } from "react-icons/gr";
import { BsCollection } from "react-icons/bs";
export const NavContainer = styled.div`
	position: absolute;
	width: 50px;
	height: 80vh;
	top: 10vh;
	right: 40px;
	z-index: 5;
	background-color: var(--dark-sec);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	@media screen and (max-width: 767px) {
		display: none;
	}

	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		display: none;
	}
`;
export const NavEntry = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;

	color: rgba(255, 255, 255, 0.1);
	color: ${(props) =>
		props.scrollPos >= props.nr * props.windowHeight &&
		props.scrollPos < (props.nr + 1) * props.windowHeight
			? "var(--bright)"
			: ""};
`;
export const BackToTopContainer = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;

	color: rgba(255, 255, 255, 0.1);
	color: ${(props) => (props.scrollPos <= props.windowHeight ? "var(--bright)" : "")};
`;

export const BackToTopIcon = styled(BsCollection)`
	font-size: 1.5rem;
`;
export const NavDate = styled.p`
	font-size: 12px;
	color: inherit;
	text-align: center;
	line-height: 0.8rem;
	margin-bottom: 2px;
`;

export const NavIcon = styled(GrSquare)``;
