import { StoryTextBoxLink, StoryTextBoxP } from "./AboutStoryElements";

export const storyPoints = [
	{
		header: "First Steps",
		text: "At the end of my bachelor's degree in Jena, I took my first step into the field of e-commerce as an intern at Intershop Communications AG. ",
	},
	{
		header: "The Transition",
		text: "I was working as an online business manager at the time and realised that I was becoming more interested in the field of development. ",
	},
	{
		header: "My First Project",
		text: (
			<StoryTextBoxP>
				At that time I started to learn HTML, CSS and JavaScript by myself. I
				decided to challenge myself by creating a simple game based on vanilla
				javascript.
				<br />
				<StoryTextBoxLink target="_blank" href="/projects#projectJump">
					Project Jump
				</StoryTextBoxLink>
				was created.
			</StoryTextBoxP>
		),
	},
	{
		header: "Getting into Tech",
		text: "After completing my bachelor's degree, I accepted an offer to continue working for Intershop as a requirements engineer. I pursued this activity for the duration of the entire master's degree.",
	},
	{
		header: "Meet Requirements",
		text: (
			<StoryTextBoxP>
				During my time as requirements engineer I worked on a lot of projects.
				Most of the time I conceptualized store-features in cooperation with our
				respective customers. Among other projects, for example I've been working
				on:
				<StoryTextBoxLink target="_blank" href="https://www.miele.com.au/">
					Miele
				</StoryTextBoxLink>
				<StoryTextBoxLink
					target="_blank"
					href="https://www.tuvsud.com/de-de/store/akademie">
					TÜV Süd
				</StoryTextBoxLink>
			</StoryTextBoxP>
		),
	},
	{
		header: "Completing the M. Sc.",
		text: "After completion of my master's degree and almost 3 years of work for Intershop I decided to set the sails back to my homeregion and moved to Dortmund.",
	},
	{
		header: "Never Call It A Day",
		text: (
			<StoryTextBoxP>
				After a short breather, I decided to start advancing my knowledge in the
				field of development instead of directly applying for jobs. After I have
				gotten inspired by a particle effect I wanted to create a similar one. So
				I developed the{" "}
				<StoryTextBoxLink target="_blank" href="/projects#particles">
					Particles Effect
				</StoryTextBoxLink>
			</StoryTextBoxP>
		),
	},
	{
		header: "It Is Pretty Fun",
		text: (
			<StoryTextBoxP>
				Searching for the next small project I can approach, I came to the
				conclusion, that I like games and I also like movies with zombies.
				Resulting I had to create the respective game. With focus on more complex
				logics to challenge my skills, I eventually developed{" "}
				<StoryTextBoxLink target="_blank" href="/projects#projectZ">
					Project Z
				</StoryTextBoxLink>{" "}
			</StoryTextBoxP>
		),
	},
	{
		header: "Taking It To The Next Level",
		text: (
			<StoryTextBoxP>
				After learning alot about basic JavaScript, HTML and CSS, I wanted to go
				further. I wanted to create a fullstack application on my own. Not only to
				learn but also to try to understand what kind of system I actually worked
				with at Intershop. So I started gathering React.js-,Express.js-, MongoDB-
				and Node.js-related knowledge from the internet.
			</StoryTextBoxP>
		),
	},
	{
		header: "Connecting The Dots",
		text: (
			<StoryTextBoxP>
				After a lot of work, figuring out how everything actually works and more
				importantly how I can use it to implement my own concepts, I managed to
				create my own prototype fullstack-application. Based on the MERN-Stack
				<StoryTextBoxLink target="_blank" href="/projects#PerfectFit">
					PerfectFit: Bike
				</StoryTextBoxLink>{" "}
				was born.
			</StoryTextBoxP>
		),
	},
];
