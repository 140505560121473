import React, { useEffect, useRef } from "react";
import {
	AboutContainer,
	AboutDetails,
	AboutHeading,
	AboutInfoContainer,
	AboutInfoImage,
	AboutInfoImageWrapper,
	AboutInfoTextH2,
	AboutInfoTextP,
	AboutInfoTextWrapper,
	AboutLanding,
	AboutMainInfo,
	AboutSkill,
	AboutSkillsWrapper,
	AttributeContainer,
	AttributeIcon,
	AttributeTextH2,
	AttributeTextP,
	AttributeWrapper,
	BasicInfoContainer,
	BasicInfoHeader,
	BasicInfoHeaderWrapper,
	BasicInfoIcon,
	BasicInfoP,
	BasicInfoStrong,
	BasicInfoTextWrapper,
	BasicInfoWrapper,
	StoryContainer,
	StoryHeader,
	StoryHeaderWrapper,
	StoryIcon,
} from "./AboutElements";
import image from "../../images/About/profilepic.png";
import { IoBulbOutline } from "react-icons/io5";
import { AiFillThunderbolt } from "react-icons/ai";
import { MdOutlineSyncProblem } from "react-icons/md";
import css from "../../images/About/skillIcons/css.png";
import expressjs from "../../images/About/skillIcons/expressjs.png";
import html5 from "../../images/About/skillIcons/html5.png";
import javascript from "../../images/About/skillIcons/javascript.png";
import jira from "../../images/About/skillIcons/jira.png";
import nodejs from "../../images/About/skillIcons/node-js.png";
import reactjs from "../../images/About/skillIcons/react-js.png";
import mongodb from "../../images/About/skillIcons/mongodb.png";
import { BiBookBookmark } from "react-icons/bi";
import { IoBusinessSharp } from "react-icons/io5";
import { BsFileEarmarkCode } from "react-icons/bs";
import { AboutStoryComponent } from "../../Components/AboutStory/AboutStoryComponent";
import Navigation from "../../Components/Navigation";
import { useIntersect } from "../../CustomHooks/useIntersect";

export const About = () => {
	const [basicInfoLeft, entry1, basicInfoLeftIntersect] = useIntersect({
		threshold: 0,
	});
	const [basicInfoRight, entry2, basicInfoRightIntersect] = useIntersect({
		threshold: 0,
	});
	const [storyContainer, entry3, storyContainerIntersect] = useIntersect({
		threshold: 0,
		rootMargin: "-100px",
	});
	const [attributeLeft, entry4, attributeLeftIntersect] = useIntersect({});
	const [attributeMiddle, entry5, attributeMiddleIntersect] = useIntersect({});
	const [attributeRight, entry6, attributeRightIntersect] = useIntersect({});

	const [aboutMainLeft, entry7, aboutMainLeftIntersect] = useIntersect({});
	const [aboutMainRight, entry8, aboutMainRightIntersect] = useIntersect({});

	return (
		<AboutContainer>
			<AboutLanding>
				<Navigation />
				<AboutHeading>ABOUT</AboutHeading>
				<AttributeContainer>
					<AttributeWrapper
						ref={attributeLeft}
						intersecting={attributeLeftIntersect}
						className="appearLeft">
						<AttributeIcon>
							<AiFillThunderbolt />
						</AttributeIcon>
						<AttributeTextH2>Quicklearner</AttributeTextH2>
						<AttributeTextP>
							Able and motivated to learn new skills quickly and
							independently.
						</AttributeTextP>
					</AttributeWrapper>
					<AttributeWrapper
						ref={attributeMiddle}
						intersecting={attributeMiddleIntersect}
						className="appearMiddle">
						<AttributeIcon>
							<IoBulbOutline />
						</AttributeIcon>
						<AttributeTextH2>Logical Thinker</AttributeTextH2>
						<AttributeTextP>
							Always keeps a calm head to inspect things from a logical
							perspective.
						</AttributeTextP>
					</AttributeWrapper>
					<AttributeWrapper
						ref={attributeRight}
						intersecting={attributeRightIntersect}
						className="appearRight">
						<AttributeIcon>
							<MdOutlineSyncProblem />
						</AttributeIcon>
						<AttributeTextH2>Problem Solver</AttributeTextH2>
						<AttributeTextP>
							Persistent in solving complex problems.
						</AttributeTextP>
					</AttributeWrapper>
				</AttributeContainer>
				<AboutInfoContainer>
					<AboutMainInfo
						ref={aboutMainLeft}
						intersecting={aboutMainLeftIntersect}
						className="appearLeft">
						<AboutInfoImageWrapper>
							<AboutInfoImage src={image}></AboutInfoImage>
						</AboutInfoImageWrapper>
						<AboutInfoTextWrapper>
							<AboutInfoTextH2>Hi, I am Steven</AboutInfoTextH2>
							<AboutInfoTextP>
								my passion is to conceptualize and develop different kinds
								of applications.
							</AboutInfoTextP>
						</AboutInfoTextWrapper>
					</AboutMainInfo>
					<AboutSkillsWrapper
						ref={aboutMainRight}
						intersecting={aboutMainRightIntersect}
						className="appearRight">
						<AboutSkill src={reactjs}></AboutSkill>
						<AboutSkill src={javascript}></AboutSkill>
						<AboutSkill src={html5}></AboutSkill>
						<AboutSkill src={mongodb}></AboutSkill>
						<AboutSkill src={expressjs}></AboutSkill>
						<AboutSkill src={nodejs}></AboutSkill>
						<AboutSkill src={jira}></AboutSkill>
						<AboutSkill src={css}></AboutSkill>
					</AboutSkillsWrapper>
				</AboutInfoContainer>
			</AboutLanding>
			<AboutDetails>
				<BasicInfoContainer>
					<BasicInfoWrapper
						ref={basicInfoLeft}
						className="appearLeft"
						intersecting={basicInfoLeftIntersect}>
						<BasicInfoHeaderWrapper>
							<BasicInfoHeader>Education</BasicInfoHeader>
							<BasicInfoIcon>
								<BiBookBookmark />
							</BasicInfoIcon>
						</BasicInfoHeaderWrapper>
						<BasicInfoTextWrapper>
							<BasicInfoP>
								I completed both my Bachelor of Economics and my Master of
								Science in Business Administration at the Friedrich
								Schiller University in Jena. During my bachelor's degree,
								I focused on the basics of economics, international
								management and developing a deeper understanding of
								economics. During my master's degree, my focus was on
								management, marketing and strategy.{" "}
							</BasicInfoP>
						</BasicInfoTextWrapper>
					</BasicInfoWrapper>
					<BasicInfoWrapper
						ref={basicInfoRight}
						className="appearRight"
						intersecting={basicInfoRightIntersect}>
						<BasicInfoHeaderWrapper>
							<BasicInfoHeader>Professional Experiences</BasicInfoHeader>
							<BasicInfoIcon>
								<IoBusinessSharp />
							</BasicInfoIcon>
						</BasicInfoHeaderWrapper>
						<BasicInfoTextWrapper>
							<BasicInfoP>
								<BasicInfoStrong>
									{" "}
									2 months: <br />
								</BasicInfoStrong>
								Online Business Management Internship |{" "}
								<BasicInfoStrong>
									Intershop Communications AG{" "}
								</BasicInfoStrong>
								<br />
								<br />
								<BasicInfoStrong>
									2.5 years:
									<br />
								</BasicInfoStrong>{" "}
								Requirements Engineering / Solution Consulting Werkstudent
								|{" "}
								<BasicInfoStrong>
									Intershop Communications AG
								</BasicInfoStrong>{" "}
							</BasicInfoP>
						</BasicInfoTextWrapper>
					</BasicInfoWrapper>
				</BasicInfoContainer>

				<StoryContainer
					ref={storyContainer}
					intersecting={storyContainerIntersect}>
					<StoryHeaderWrapper>
						<StoryHeader>How I got into Development</StoryHeader>
						<StoryIcon>
							<BsFileEarmarkCode />
						</StoryIcon>
					</StoryHeaderWrapper>
					<AboutStoryComponent />
				</StoryContainer>
			</AboutDetails>
		</AboutContainer>
	);
};
