import styled from "styled-components";
import { Link } from "react-router-dom";
import { animated } from "@react-spring/web";
import { image } from "../../images/About/profilepic.png";
export const HomeContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background: var(--gradient-fallback); /* fallback for old browsers */
	background: var(--gradient-bg-webkit);
	background: var(--gradient-bg);
`;

export const HomeTextArea = styled.div`
	width: 70%;
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const HomeText = styled.h1`
	width: 100vw;
	text-align: center;
	font-size: 7rem;
	font-weight: 700;
	letter-spacing: 3px;
	margin-bottom: 40px;
	color: var(--text-bright);
	cursor: pointer;
	transform: rotate(0.005turn) translate(0px, 0vh);
	animation-name: appearingTop;
	animation-duration: 1000ms;

	@keyframes appearingTop {
		from {
			transform: rotate(0.005turn) translate(0px, -100vh);
			opacity: 0;
		}
		to {
			transform: rotate(0.005turn) translate(0px, 0vh);
			opacity: 1;
		}
	}

	@media screen and (max-width: 767px) {
		font-size: 4rem;
		transform: rotate(0turn);
		@keyframes appearingTop {
			from {
				transform: translate(0px, -100vh);
				opacity: 0;
			}
			to {
				transform: translate(0px, 0vh);
				opacity: 1;
			}
		}
		&.mobile {
			font-size: 4rem;
			transform: rotate(0turn);
			display: ${(props) => (props.clicked ? "flex" : "block")};
			flex-direction: ${(props) => (props.clicked ? "column" : "")};
		}
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 4rem;
		letter-spacing: 10px;
		@keyframes appearingTop {
			from {
				transform: translate(0px, -100vh);
				opacity: 0;
			}
			to {
				transform: translate(0px, 0vh);
				opacity: 1;
			}
		}
		&.mobile {
			font-size: 6rem;
			transform: rotate(0turn);
			display: ${(props) => (props.clicked ? "flex" : "block")};
			flex-direction: ${(props) => (props.clicked ? "column" : "")};
		}
	}
	@media screen and (min-device-width: 1025px) {
		&.mobile {
			font-size: 6rem;
			transform: rotate(0turn);
			display: ${(props) => (props.clicked ? "flex" : "block")};
			flex-direction: ${(props) => (props.clicked ? "column" : "")};
		}
	}
	@media screen and (min-device-width: 1025px) and (max-device-width: 1300px) {
		font-size: 5rem;
		letter-spacing: 10px;
	}
`;
export const NavWrapper = styled.span``;
export const NavSpan = styled(animated.span)`
	transition: margin 1s ease;
	margin: ${(props) => (props.hovering ? "40px" : "")};
	text-shadow: ${(props) =>
		props.hovering
			? "0px 0px 6px rgba(232,237,223,0.75)"
			: "0px 0px 6px rgba(232,237,223,0.4)"};
`;

export const NavLinkWrapper = styled(animated.span)``;
export const NavLink = styled(Link)`
	font-size: 7rem;
	text-decoration: none;
	margin: 40px;
	transition: all 0.8s ease;
	opacity: 0.2;

	@media screen and (max-width: 767px) {
		font-size: 3rem;
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
		animation-duration: 2s;
		animation-name: appearing;
		@keyframes appearing {
			from {
				opacity: 0;
				color: #ffffff20;
			}
			to {
				opacity: 1;
				color: var(--contrast);
			}
		}
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 4rem;
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
		animation-duration: 2s;
		animation-name: appearing;
		@keyframes appearing {
			from {
				opacity: 0;
				color: #ffffff20;
			}
			to {
				opacity: 1;
				color: var(--contrast);
			}
		}
	}
	@media screen and (min-device-width: 1025px) and (max-device-width: 1300px) {
		font-size: 5rem;
		letter-spacing: 10px;
	}
	@media screen and (min-device-width: 1025px) {
		&.mobile {
			font-size: 5rem;
			color: var(--contrast);
			opacity: 1;
			text-shadow: 0px 0px 6px var(--contrast-o);
			animation-duration: 2s;
			animation-name: appearing;
			@keyframes appearing {
				from {
					opacity: 0;
					color: #ffffff20;
				}
				to {
					opacity: 1;
					color: var(--contrast);
				}
			}
		}
	}

	&:hover {
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
	}
`;

export const LandingContactLink = styled.a`
	font-size: 7rem;
	text-decoration: none;
	margin: 40px;
	transition: all 0.8s ease;
	opacity: 0.2;

	@media screen and (max-width: 767px) {
		font-size: 3rem;
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
		animation-duration: 2s;
		animation-name: appearing;
		@keyframes appearing {
			from {
				opacity: 0;
				color: #ffffff20;
			}
			to {
				opacity: 1;
				color: var(--contrast);
			}
		}
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 4rem;
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
		animation-duration: 2s;
		animation-name: appearing;
		@keyframes appearing {
			from {
				opacity: 0;
				color: #ffffff20;
			}
			to {
				opacity: 1;
				color: var(--contrast);
			}
		}
	}
	@media screen and (min-device-width: 1025px) and (max-device-width: 1300px) {
		font-size: 5rem;
		letter-spacing: 10px;
	}
	@media screen and (min-device-width: 1025px) {
		&.mobile {
			font-size: 5rem;
			color: var(--contrast);
			opacity: 1;
			text-shadow: 0px 0px 6px var(--contrast-o);
			animation-duration: 2s;
			animation-name: appearing;
			@keyframes appearing {
				from {
					opacity: 0;
					color: #ffffff20;
				}
				to {
					opacity: 1;
					color: var(--contrast);
				}
			}
		}
	}

	&:hover {
		color: var(--contrast);
		opacity: 1;
		text-shadow: 0px 0px 6px var(--contrast-o);
	}
`;

export const HomeSubText = styled(animated.h2)`
	width: 30%;
	text-align: center;
	position: relative;
	font-size: 1.5rem;
	color: var(--contrast);
	letter-spacing: 3px;
	text-shadow: 0px 0px 6px var(--contrast-o);

	transform: translate(0px, 0vh);
	animation-name: appearingBottom;
	animation-duration: 1000ms;

	@keyframes appearingBottom {
		from {
			transform: rotate(0.005turn) translate(0px, 100vh);
			opacity: 0;
		}
		to {
			transform: rotate(0.005turn) translate(0px, 0vh);
		}
	}

	@media screen and (max-width: 767px) {
		font-size: 1rem;
		width: 80%;
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		font-size: 1.5rem;
		width: 80%;
	}
`;
export const LinkSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-right: 30%;
	text-align: end;
`;

//!! Mobile adjustments

export const MobileInstructions = styled.div`
	display: ${(props) => (props.clicked ? "none" : "block")};

	position: absolute;
	bottom: 10px;
	padding: 20px 40px;
	font-size: 0.8;
	background-color: #ffffff10;
	backdrop-filter: blur(12px);
	--webkit-backdrop-filter: blur(12px);
	border-radius: 5px;
	color: var(--contrast);
	letter-spacing: 3px;
	text-shadow: 0px 0px 6px var(--contrast-o);
	opacity: 0;
	animation-delay: 3s;
	animation-duration: 1.5s;
	animation-name: blinking;
	animation-iteration-count: infinite;
	@keyframes blinking {
		from {
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}

		80% {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}
	@media screen and (min-device-width: 768px) {
		font-size: 1.8rem;
		bottom: 40px;
	}
`;

export const MobileInteraction = styled.div`
	display: ${(props) => (props.clicked ? "none" : "block")};
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 1;
`;
