import React, { useContext, useRef, useState } from "react";
import {
	HomeContainer,
	HomeSubText,
	HomeText,
	HomeTextArea,
	LandingContactLink,
	MobileInstructions,
	MobileInteraction,
	NavLink,
	NavSpan,
	NavWrapper,
} from "./HomeElements";
import { useSpring } from "react-spring";
import { TouchContext } from "../../Contexts/TouchContextProvider";
export const Home = (props) => {
	const [hovering, setHovering] = useState(false);
	const [hovering1, setHovering1] = useState(false);
	const [hovering2, setHovering2] = useState(false);
	const [hovering3, setHovering3] = useState(false);
	const [mobileClicked, setMobileClicked] = useState(false);
	// const transition = useTransition(hovering, {
	// 	from: { x: -100, y: 800, opacity: 1 },
	// 	enter: { opacity: 1 },
	// 	leave: { x: -100, y: 800, opacity: 0 },
	// });
	const mailLink1 = useRef();
	const mailLink2 = useRef();

	function UnCryptMailto(s) {
		var n = 0;
		var r = "";
		for (var i = 0; i < s.length; i++) {
			n = s.charCodeAt(i);
			if (n >= 8364) {
				n = 128;
			}
			r += String.fromCharCode(n - 1);
		}
		return r + "?subject=We want to get to know you, Steven!";
	}

	function linkTo_UnCryptMailto(s, link) {
		switch (link) {
			case "mailLink1":
				mailLink1.current.href = UnCryptMailto(s);
				break;
			case "mailLink2":
				mailLink2.current.href = UnCryptMailto(s);
				break;
			default:
				return;
		}
	}
	const touch = useContext(TouchContext);

	const springProps = useSpring({
		to: { opacity: 1 },
		from: { opacity: 0 },
		delay: 500,
		config: { duration: 5000 },
	});

	const handleMobileClick = () => {
		setMobileClicked(true);
	};
	const mouseLeaveHandler = () => {
		setHovering(false);
		setHovering1(false);
		setHovering2(false);
		setHovering3(false);
	};
	return (
		<HomeContainer>
			{!touch ? (
				<>
					<HomeTextArea
						onMouseEnter={() => setHovering(!hovering)}
						onMouseLeave={mouseLeaveHandler}>
						<HomeText hovering={hovering}>
							<NavWrapper
								onMouseEnter={() => setHovering1(!hovering1)}
								onMouseLeave={() => setHovering1(!hovering1)}
								hovering={hovering}>
								{!hovering1 ? (
									<NavSpan hovering={hovering}>WEL</NavSpan>
								) : (
									<NavLink to="/about">ABOUT</NavLink>
								)}
							</NavWrapper>
							<NavWrapper
								onMouseEnter={() => setHovering2(!hovering2)}
								onMouseLeave={() => setHovering2(!hovering2)}>
								{!hovering2 ? (
									<NavSpan hovering={hovering}>CO</NavSpan>
								) : (
									<NavLink to="/projects">PROJECTS</NavLink>
								)}
							</NavWrapper>
							<NavWrapper
								onMouseEnter={() => setHovering3(!hovering3)}
								onMouseLeave={() => setHovering3(!hovering3)}>
								{!hovering3 ? (
									<NavSpan hovering={hovering}>ME</NavSpan>
								) : (
									<LandingContactLink
										ref={mailLink1}
										onClick={() =>
											linkTo_UnCryptMailto(
												"nbjmup;tdivm{tufwfo:5Ahny/ef",
												"mailLink1"
											)
										}>
										CONTACT
									</LandingContactLink>
								)}
							</NavWrapper>
						</HomeText>

						<HomeSubText style={springProps}>
							To the portfolio page of <br /> Steven Schulz
						</HomeSubText>
					</HomeTextArea>
				</>
			) : (
				<HomeTextArea>
					<HomeText className="mobile" clicked={mobileClicked}>
						<NavWrapper>
							{!mobileClicked ? (
								<NavSpan>WEL</NavSpan>
							) : (
								<NavLink className={touch ? "mobile" : ""} to="/about">
									ABOUT
								</NavLink>
							)}
						</NavWrapper>
						<NavWrapper>
							{!mobileClicked ? (
								<NavSpan>CO</NavSpan>
							) : (
								<NavLink className={touch ? "mobile" : ""} to="/projects">
									PROJECTS
								</NavLink>
							)}
						</NavWrapper>
						<NavWrapper>
							{!mobileClicked ? (
								<NavSpan>ME</NavSpan>
							) : (
								<LandingContactLink
									ref={mailLink2}
									onClick={() =>
										linkTo_UnCryptMailto(
											"nbjmup;tdivm{tufwfo:5Ahny/ef",
											"mailLink2"
										)
									}>
									CONTACT
								</LandingContactLink>
							)}{" "}
						</NavWrapper>
					</HomeText>
					<HomeSubText style={springProps}>
						To the portfolio page of <br /> Steven Schulz
					</HomeSubText>
					<MobileInstructions clicked={mobileClicked}>
						Tap anywhere
					</MobileInstructions>
					<MobileInteraction
						clicked={mobileClicked}
						onClick={handleMobileClick}></MobileInteraction>
				</HomeTextArea>
			)}
		</HomeContainer>
	);
};
