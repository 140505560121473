import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
	MobileDescription,
	ProjectGIF,
	ProjectMobileShowCase,
	ProjectShowCaseWrapper,
} from "../ProjectJump/ProjectJumpElements";
import { GameScreen, PropImages } from "./ProjectZElements";
import playerIMG from "../../../images/ProjectZ/player.png";
import zIMG from "../../../images/ProjectZ/zombie.png";
import background from "../../../images/ProjectZ/background.png";
import gif from "../../../videos/gif/ProjectZ.gif";
import { TouchContext } from "../../../Contexts/TouchContextProvider";

export const ProjectZ = () => {
	const touch = useContext(TouchContext);
	const wrapper = useRef();
	const id = useRef(null);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [ready, setReady] = useState(false);
	useLayoutEffect(() => {
		if (wrapper.current) {
			setHeight(wrapper.current.offsetHeight);
			setWidth(wrapper.current.offsetWidth);
			setReady(true);
		}
	}, []);
	useEffect(() => {
		if (ready) {
			var canvas = document.getElementById("gameScreen");
			canvas.width = width;
			canvas.height = height;
			var background = document.getElementById("background");
			var playerIMG = document.getElementById("player");
			var zIMG = document.getElementById("zombie");
			var ctx = canvas.getContext("2d");

			class Player {
				constructor(r, color) {
					this.r = r;
					this.color = color;
					this.x = canvas.width / 2;
					this.y = canvas.height / 2;
					this.money = playerStat.money;
					this.gun = playerStat.gun;
				}

				static directionX = 0;

				static directionY = 0;
				static radians = 0;
				static angle = 0;

				update() {}
				draw(ctx) {
					// ctx.fillStyle = this.color;
					// ctx.beginPath();
					// ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
					// ctx.fill();

					ctx.translate(this.x, this.y);
					ctx.rotate((Math.PI / 180) * (Player.angle + 90));
					ctx.translate(-this.x, -this.y);

					ctx.drawImage(
						playerIMG,
						this.x - this.r * 2,
						this.y - this.r * 2,
						this.r * 4,
						this.r * 4
					);

					ctx.setTransform(1, 0, 0, 1, 0, 0);
				}

				shoot() {
					if (this.gun == "pistol") {
						bulletArray.push(
							new Bullet(
								this.x + Player.directionX * 30,
								this.y + Player.directionY * 30,
								bulletStat.speed,
								bulletStat.damage
							)
						);
						mouse.mouseDown = false;
					}
					if (this.gun == "rifle") {
						if (bulletStat.delay < 10) {
							bulletStat.delay += 1;
						} else {
							bulletArray.push(
								new Bullet(
									this.x + Player.directionX * 30,
									this.y + Player.directionY * 30,
									bulletStat.speed,
									bulletStat.damage
								)
							);
							bulletStat.delay = 1;
						}
					}
					if (this.gun == "minigun") {
						if (bulletStat.delay < 10) {
							bulletStat.delay += 1;
						} else {
							bulletArray.push(
								new Bullet(
									this.x + Player.directionX * 30,
									this.y + Player.directionY * 30,
									bulletStat.speed,
									bulletStat.damage
								)
							);
							bulletStat.delay = 1;
						}
					}
				}
				calcDirection() {
					this.getDirectionX();
					this.getDirectionY();
					Player.radians = Math.atan2(Player.directionY, Player.directionX);
					Player.angle = (180 * Player.radians) / Math.PI;
				}
				getDirectionX() {
					Player.directionX =
						(mouse.x - this.x) /
						Math.sqrt(
							Math.pow(mouse.y - this.y, 2) + Math.pow(mouse.x - this.x, 2)
						);
				}
				getDirectionY() {
					Player.directionY =
						(mouse.y - this.y) /
						Math.sqrt(
							Math.pow(mouse.y - this.y, 2) + Math.pow(mouse.x - this.x, 2)
						);
				}
			}

			class Bullet {
				constructor(x, y, speed, damage) {
					this.x = x;
					this.y = y;
					this.r = bulletStat.r;
					this.color = "yellow";
					this.speed = speed;
					this.damage = damage;
					this.directionX = this.getDirectionX(mouse);
					this.directionY = this.getDirectionY(mouse);
					this.collisionEnemy = false;
					this.outOfScreen = false;
				}
				draw(ctx) {
					ctx.fillStyle = this.color;
					ctx.beginPath();
					ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
					ctx.fill();
				}
				move() {
					this.x += this.directionX * this.speed;
					this.y += this.directionY * this.speed;
				}
				getDirectionX(object) {
					if (this.directionX == null) {
						return (
							(object.x - this.x) /
							Math.sqrt(
								Math.pow(object.y - this.y, 2) +
									Math.pow(object.x - this.x, 2)
							)
						);
					} else {
						return this.directionX;
					}
				}
				getDirectionY(object) {
					if (this.directionY == null) {
						return (
							(object.y - this.y) /
							Math.sqrt(
								Math.pow(object.y - this.y, 2) +
									Math.pow(object.x - this.x, 2)
							)
						);
					} else {
						return this.directionY;
					}
				}

				bulletHitEnemy(object) {
					if (
						Math.sqrt(
							Math.pow(object.x - this.x, 2) +
								Math.pow(object.y - this.y, 2)
						) <=
						object.r + this.r
					) {
						return true;
					}
				}
				bulletOutOfScreen() {
					if (
						this.x < 0 ||
						this.y < 0 ||
						this.x > canvas.width ||
						this.y > canvas.height
					) {
						return true;
					}
				}
			}

			class Enemy {
				constructor(x, y, speed, r, color, hp) {
					this.x = x;
					this.y = y;
					this.r = r;
					this.color = color;
					this.directionY = this.getDirectionY(player);
					this.directionX = this.getDirectionX(player);
					this.speed = speed;
					this.hp = hp;
					this.maxHP = hp;
					this.collision = false;
					this.hit = false;
					this.kickBackRange = 0;
					this.radians = Math.atan2(this.directionY, this.directionX);
					this.angle = (180 * this.radians) / Math.PI;
				}
				draw(ctx) {
					ctx.fillStyle = "yellow";
					ctx.fillRect(this.x - this.r, this.y - this.r - 10, this.r * 2, 4);
					ctx.fillStyle = "red";
					ctx.fillRect(
						this.x - this.r,
						this.y - this.r - 10,
						(this.r * 2 * this.hp) / this.maxHP,
						4
					);
					ctx.translate(this.x, this.y);
					ctx.rotate((Math.PI / 180) * (this.angle + 90));
					ctx.translate(-this.x, -this.y);

					ctx.drawImage(
						zIMG,
						this.x - this.r,
						this.y - this.r,
						this.r * 2,
						this.r * 2
					);

					ctx.setTransform(1, 0, 0, 1, 0, 0);
				}
				move() {
					this.checkCollision(player);
					if (this.kickBackRange > 0) {
						this.speed = -0.5;
						this.kickBackRange -= 1;
					} else if (this.kickBackRange == 0) {
						this.speed = 0.5;
					}

					if (this.collision == false) {
						this.x += this.directionX * this.speed;

						this.y += this.directionY * this.speed;
					}
				}

				checkCollision(object) {
					if (
						Math.sqrt(
							Math.pow(object.x - this.x, 2) +
								Math.pow(object.y - this.y, 2)
						) <=
						object.r + this.r + 3
					) {
						this.collision = true;
					}
				}

				getDirectionX(object) {
					if (this.directionX == null) {
						return (
							(object.x - this.x) /
							Math.sqrt(
								Math.pow(object.y - this.y, 2) +
									Math.pow(object.x - this.x, 2)
							)
						);
					} else {
						return this.directionX;
					}
				}
				getDirectionY(object) {
					if (this.directionY == null) {
						return (
							(object.y - this.y) /
							Math.sqrt(
								Math.pow(object.y - this.y, 2) +
									Math.pow(object.x - this.x, 2)
							)
						);
					} else {
						return this.directionY;
					}
				}
				hitByBullet(bullet) {
					if (
						Math.sqrt(
							Math.pow(bullet.x - this.x, 2) +
								Math.pow(bullet.y - this.y, 2)
						) <=
						bullet.r + this.r
					) {
						this.hit = true;
					}
				}
			}

			window.addEventListener("mousedown", function (e) {
				mouse.mouseDown = true;
			});
			window.addEventListener("mouseup", function (e) {
				mouse.mouseDown = false;
			});
			window.addEventListener("mousemove", function (e) {
				var rect = e.target.getBoundingClientRect();
				mouse.x = e.clientX - rect.left;
				mouse.y = e.clientY - rect.top;
			});
			window.addEventListener("keydown", function (e) {
				if (e.keyCode == 32 && e.target == document.body) {
					e.preventDefault();
				}
				if (e.keyCode == 49) {
					keyInputs.eins = true;
				} else if (e.keyCode == 50) {
					keyInputs.zwei = true;
				} else if (e.keyCode == 51) {
					keyInputs.drei = true;
				} else if (e.keyCode == 32) {
					keyInputs.space = true;
				}
			});
			window.addEventListener("keyup", function (e) {
				if (e.keyCode == 49) {
					keyInputs.eins = false;
				} else if (e.keyCode == 50) {
					keyInputs.zwei = false;
				} else if (e.keyCode == 51) {
					keyInputs.drei = false;
				} else if (e.keyCode == 32) {
					keyInputs.space = false;
				}
			});
			var mouse = {
				mouseDown: false,
				x: null,
				y: null,
			};

			var keyInputs = {
				eins: false,
				zwei: false,
				drei: false,
				space: false,
			};

			var bulletStat = {
				speed: 4,
				damage: 1,
				delay: 1,
				r: 4,
			};

			var playerStat = {
				money: 0,
				gun: "pistol",
			};

			var bulletArray = [];
			var enemyArray = [];
			var player;

			var tile1 = {
				x: 0,
				y: 0,
				size: 32,
			};
			var tile2 = {
				x: 32,
				y: 0,
				size: 32,
			};
			var tile3 = {
				x: 64,
				y: 0,
				size: 32,
			};
			var tile4 = {
				x: 0,
				y: 32,
				size: 32,
			};
			var tile5 = {
				x: 32 * 5,
				y: 32,
				size: 32,
			};
			var tile6 = {
				x: 4 * 32,
				y: 0,
				size: 32,
			};

			var backgroundTiles = [[]];

			function createBackground() {
				for (var i = 0; i < canvas.width / 32; i++) {
					backgroundTiles.push([]);
					for (var j = 0; j < canvas.height / 32; j++) {
						backgroundTiles[i].push(randomTile());
					}
				}
			}

			function randomTile() {
				let random = Math.random();
				if (random < 0.16) {
					return tile1;
				} else if (random < 0.16 * 2) {
					return tile2;
				} else if (random < 0.16 * 3) {
					return tile3;
				} else if (random < 0.16 * 4) {
					return tile4;
				} else if (random < 0.16 * 5) {
					return tile5;
				} else {
					return tile6;
				}
			}

			var GameState = 0;
			var Wave = 1;
			var Killcount = 0;
			var zSpawned = false;

			// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			// ! GAMELOOP !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

			function gameLoop() {
				if (GameState === 0) {
					draw();
					inputHandler();
				} else if (GameState === 1) {
					gameOver();
					nextLevel();

					roundInt(8 * (Wave / 2), 8 + 2 * Wave, 0.5);

					handleBullets();
					zombieDead();

					inputHandler();
					for (var i = 0; i < enemyArray.length; i++) {
						for (var j = 0; j < bulletArray.length; j++)
							enemyArray[i].hitByBullet(bulletArray[j]);
					}
					moveObjects();
					draw();
				} else if (GameState === 2) {
					inputHandler();
					draw();
				} else if (GameState === 3) {
					inputHandler();
					draw();
				}
				id.current = window.requestAnimationFrame(gameLoop);
			}
			// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			// ! DRAW !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			function draw() {
				if (GameState == 0) {
					startScreen();
				} else if (GameState == 1) {
					clearScreen();

					drawObjects(ctx);
				} else if (GameState == 2) {
					buyScreen();
				} else if (GameState == 3) {
					gameOverScreen();
				}
			}

			// FUNCTIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			function startScreen() {
				ctx.fillStyle = "#293133";
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				ctx.font = "bold 81px Serif ";
				ctx.textAlign = "center";
				ctx.fillStyle = "black";
				ctx.fillText("PROJECT Z", canvas.width / 2, canvas.height / 2 - 104);

				ctx.font = "bold 80px Serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "green";
				ctx.fillText("PROJECT Z", canvas.width / 2, canvas.height / 2 - 100);
				ctx.font = "48px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText("Press Space to start", canvas.width / 2, canvas.height / 2);
			}

			function buyScreen() {
				ctx.fillStyle = "#293133";
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				// ! Headline
				ctx.font = "80px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"B U Y S C R E E N",
					canvas.width / 2,
					canvas.height / 2 - 100
				);

				ctx.font = "48px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"Press Space to start next wave",
					canvas.width / 2,
					canvas.height / 2
				);
				// ! PISTOL SCREEN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				if (playerStat.gun == "pistol") {
					ctx.font = "40px serif ";
					ctx.textAlign = "center";
					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 1 to upgrade weapon damage (10$)",
						canvas.width / 2,
						canvas.height / 2 + 100
					);
					if (bulletStat.damage <= 20 || bulletStat.damage === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					} else if (bulletStat.damage > 20) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (
						var i = 1;
						i <= bulletStat.damage && bulletStat.damage <= 20;
						i++
					)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (var i = 1; i <= bulletStat.damage - 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);

					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.damage + "/20",
						canvas.width / 2 + 300,
						canvas.height / 2 + 148
					);

					ctx.font = "40px serif ";
					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 2 to upgrade weapon speed (10$)",
						canvas.width / 2,
						canvas.height / 2 + 200
					);
					if (bulletStat.speed <= 20 || bulletStat.speed === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					} else if (bulletStat.speed > 20) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (var i = 1; i <= bulletStat.speed && bulletStat.speed <= 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (var i = 1; i <= bulletStat.speed - 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.speed + "/20",
						canvas.width / 2 + 300,
						canvas.height / 2 + 248
					);
					ctx.font = "40px serif ";

					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 3 to buy rifle (150$)",
						canvas.width / 2,
						canvas.height / 2 + 310
					);

					// ! RIFLE SCREEN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
					// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				} else if (playerStat.gun == "rifle") {
					ctx.font = "40px serif";
					ctx.textAlign = "center";
					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 1 to upgrade weapon damage (10$)",
						canvas.width / 2,
						canvas.height / 2 + 100
					);
					if (bulletStat.damage <= 20 || bulletStat.damage === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					} else if (bulletStat.damage > 20) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (
						var i = 1;
						i <= bulletStat.damage && bulletStat.damage <= 20;
						i++
					)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (var i = 1; i <= bulletStat.damage - 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);
					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.damage + "/40",
						canvas.width / 2 + 300,
						canvas.height / 2 + 148
					);
					ctx.font = "40px serif";

					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 2 to upgrade weapon speed (10$)",
						canvas.width / 2,
						canvas.height / 2 + 200
					);
					if (bulletStat.speed <= 20 || bulletStat.speed === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					} else if (bulletStat.speed > 20) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (var i = 1; i <= bulletStat.speed && bulletStat.speed <= 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (var i = 1; i <= bulletStat.speed - 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);

					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.speed + "/40",
						canvas.width / 2 + 300,
						canvas.height / 2 + 248
					);
					ctx.font = "40px serif";

					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 3 to buy minigun (300$)",
						canvas.width / 2,
						canvas.height / 2 + 310
					);

					// ! MINIGUN SCREEN !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
					// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
				} else if (playerStat.gun == "minigun") {
					ctx.font = "40px serif";
					ctx.textAlign = "center";
					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 1 to upgrade weapon damage (10$)",
						canvas.width / 2,
						canvas.height / 2 + 100
					);
					if (bulletStat.damage <= 20 || bulletStat.damage === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					} else if (bulletStat.damage > 20 && bulletStat.damage <= 40) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					} else if (bulletStat.damage >= 40) {
						ctx.fillStyle = "red";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 130,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (
						var i = 1;
						i <= bulletStat.damage && bulletStat.damage <= 20;
						i++
					)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (
						var i = 1;
						i <= bulletStat.damage - 20 && bulletStat.damage <= 40;
						i++
					)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);

					ctx.fillStyle = "purple";
					for (var i = 1; i <= bulletStat.damage - 40; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 130,
							10,
							20
						);

					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.damage + "/60",
						canvas.width / 2 + 300,
						canvas.height / 2 + 148
					);
					ctx.font = "40px serif";
					ctx.fillStyle = "#FFA142";
					ctx.fillText(
						"Press 2 to upgrade weapon speed (10$)",
						canvas.width / 2,
						canvas.height / 2 + 200
					);
					if (bulletStat.speed <= 20 || bulletStat.speed === undefined) {
						ctx.fillStyle = "yellow";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					} else if (bulletStat.speed > 20 && bulletStat.speed < 40) {
						ctx.fillStyle = "green";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					} else if (bulletStat.speed > 40) {
						ctx.fillStyle = "red";
						for (var i = 1; i <= 20; i++)
							ctx.fillRect(
								canvas.width / 2 - 200 + i * 20,
								canvas.height / 2 + 230,
								10,
								20
							);
					}
					ctx.fillStyle = "green";
					for (var i = 1; i <= bulletStat.speed && bulletStat.speed <= 20; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.fillStyle = "red";
					for (
						var i = 1;
						i <= bulletStat.speed - 20 && bulletStat.speed <= 40;
						i++
					)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.fillStyle = "purple";
					for (var i = 1; i <= bulletStat.speed - 40; i++)
						ctx.fillRect(
							canvas.width / 2 - 200 + i * 20,
							canvas.height / 2 + 230,
							10,
							20
						);
					ctx.font = "25px serif ";
					ctx.fillStyle = "white";
					ctx.fillText(
						bulletStat.speed + "/60",
						canvas.width / 2 + 300,
						canvas.height / 2 + 248
					);
				}

				// ! Statanzeige
				ctx.font = "20px serif";
				ctx.textAlign = "right";
				ctx.fillStyle = "#FFA142";
				ctx.fillText("Cash:" + " " + playerStat.money, canvas.width - 150, 30);
				ctx.font = "60px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"Killcount: " + Killcount,
					canvas.width / 2,
					canvas.height / 2 - 200
				);
			}

			function gameOverScreen() {
				ctx.fillStyle = "#293133";
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				ctx.font = "60px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"Killcount: " + Killcount,
					canvas.width / 2,
					canvas.height / 2 + 300
				);

				ctx.font = "60px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					" You survived " + (Wave - 1) + " Waves!",
					canvas.width / 2,
					canvas.height / 2 + 200
				);

				ctx.font = "80px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"G A M E   O V E R",
					canvas.width / 2,
					canvas.height / 2 - 100
				);

				ctx.font = "48px serif";
				ctx.textAlign = "center";
				ctx.fillStyle = "#FFA142";
				ctx.fillText(
					"Press Space to start over",
					canvas.width / 2,
					canvas.height / 2
				);
			}

			function clearScreen() {
				ctx.fillStyle = "#293133";
				ctx.fillRect(0, 0, canvas.width, canvas.height);
				for (var i = 0; i < canvas.width / 32; i++) {
					for (var j = 0; j < canvas.height / 32; j++) {
						// //ctx.drawImage(background,randomTile().x,randomTile().y,i*32,j*32,32,32)

						ctx.drawImage(
							background,
							backgroundTiles[i][j].x,
							backgroundTiles[i][j].y,
							32,
							32,
							i * 32,
							j * 32,
							32,
							32
						);
					}
				}

				ctx.font = "20px serif";
				ctx.textAlign = "left";
				ctx.fillStyle = "#FFA142";
				ctx.fillText("Zombies:" + " " + enemyArray.length, 10, 30);
				ctx.textAlign = "right";

				ctx.fillStyle = "#FFA142";
				ctx.fillText("Cash:" + " " + playerStat.money, canvas.width - 150, 30);
				ctx.textAlign = "center";

				ctx.fillStyle = "#FFA142";
				ctx.fillText("Wave:" + " " + Wave, canvas.width / 2, 30);
			}

			function inputHandler() {
				if (GameState == 0) {
					if (keyInputs.space == true) {
						GameState = 1;
					}
				} else if (GameState == 1) {
					if (mouse.mouseDown == true) {
						player.shoot();
					}
				} else if (GameState == 2) {
					if (playerStat.gun == "pistol") {
						if (
							keyInputs.eins == true &&
							bulletStat.damage < 20 &&
							playerStat.money >= 10
						) {
							bulletStat.damage += 1;
							playerStat.money -= 10;
							keyInputs.eins = false;
						}
						if (
							keyInputs.zwei == true &&
							bulletStat.speed < 20 &&
							playerStat.money >= 10
						) {
							bulletStat.speed += 1;
							playerStat.money -= 10;
							keyInputs.zwei = false;
						}
						if (keyInputs.drei == true && playerStat.money >= 150) {
							playerStat.gun = "rifle";
							bulletStat.r = 6;
							playerStat.money -= 150;
							keyInputs.drei = false;
						}
					} else if (playerStat.gun == "rifle") {
						if (
							keyInputs.eins == true &&
							bulletStat.damage < 40 &&
							playerStat.money >= 10
						) {
							bulletStat.damage += 1;
							playerStat.money -= 10;
							keyInputs.eins = false;
						}
						if (
							keyInputs.zwei == true &&
							bulletStat.speed < 40 &&
							playerStat.money >= 10
						) {
							bulletStat.speed += 1;
							playerStat.money -= 10;
							keyInputs.zwei = false;
						}
						if (keyInputs.drei == true && playerStat.money >= 300) {
							playerStat.gun = "minigun";
							bulletStat.r = 8;
							bulletStat.delay = 9;
							playerStat.money -= 300;
							keyInputs.drei = false;
						}
					} else if (playerStat.gun == "minigun") {
						if (
							keyInputs.eins == true &&
							bulletStat.damage < 60 &&
							playerStat.money >= 10
						) {
							bulletStat.damage += 1;
							playerStat.money -= 10;
							keyInputs.eins = false;
						}
						if (
							keyInputs.zwei == true &&
							bulletStat.speed < 60 &&
							playerStat.money >= 10
						) {
							bulletStat.speed += 1;
							playerStat.money -= 10;
							keyInputs.zwei = false;
						}
					}
					if (keyInputs.space == true) {
						Wave += 1;
						bulletArray = [];

						zSpawned = false;
						GameState = 1;
					}
				} else if (GameState == 3) {
					if (keyInputs.space == true) {
						bulletStat.damage = 1;
						bulletStat.speed = 4;
						playerStat.gun = "pistol";
						Wave = 1;
						GameState = 1;
						enemyArray = [];
						bulletArray = [];
						zSpawned = false;
						playerStat.money = 0;
						Killcount = 0;
					}
				}
			}

			function gameOver() {
				for (var i = 0; i < enemyArray.length; i++) {
					if (enemyArray[i].collision === true) {
						GameState = 3;
					}
				}
			}

			function nextLevel() {
				if (enemyArray.length <= 0 && zSpawned === true) {
					GameState = 2;
				}
			}

			function moveObjects() {
				for (var i = 0; i < enemyArray.length; i++) {
					enemyArray[i].move();
				}

				for (var i = 0; i < bulletArray.length; i++) {
					bulletArray[i].move();
				}

				player.calcDirection();
			}

			function drawObjects(ctx) {
				player.draw(ctx);
				for (var i = 0; i < enemyArray.length; i++) {
					enemyArray[i].draw(ctx);
				}

				for (var i = 0; i < bulletArray.length; i++) {
					bulletArray[i].draw(ctx);
				}
			}

			function handleBullets() {
				for (var i = 0; i < bulletArray.length; i++) {
					for (var j = 0; j < enemyArray.length; j++) {
						if (bulletArray[i] != undefined) {
							if (bulletArray[i].bulletOutOfScreen() == true) {
								bulletArray.splice(i, 1);
							} else if (
								bulletArray[i].bulletHitEnemy(enemyArray[j]) == true
							) {
								enemyArray[j].hp -= bulletArray[i].damage;
								enemyArray[j].kickBackRange = bulletArray[i].r * 2;
								bulletArray.splice(i, 1);
							}
						}
					}
				}
			}

			function zombieDead() {
				for (var j = 0; j < enemyArray.length; j++) {
					if (enemyArray[j].hp <= 0) {
						playerStat.money += 3;
						Killcount += 1;
						enemyArray.splice(j, 1);
					}
				}
			}

			function enemyInt(amount, hp, speed) {
				for (var i = 0; i < amount; i++) {
					var randomSide = Math.random();
					if (randomSide < 0.25) {
						enemyArray.push(
							new Enemy(
								0 - Math.random() * 500,
								Math.random() * canvas.height,
								speed,
								20,
								"green",
								hp
							)
						);
					} else if (randomSide < 0.5) {
						enemyArray.push(
							new Enemy(
								canvas.width + Math.random() * 500,
								Math.random() * canvas.height,
								speed,
								20,
								"green",
								hp
							)
						);
					} else if (randomSide < 0.75) {
						enemyArray.push(
							new Enemy(
								Math.random() * canvas.width,
								0 - Math.random() * 500,
								speed,
								20,
								"green",
								hp
							)
						);
					} else {
						enemyArray.push(
							new Enemy(
								Math.random() * canvas.width,
								canvas.height + Math.random() * 500,
								speed,
								20,
								"green",
								hp
							)
						);
					}
				}
			}

			function roundInt(amount, hp, speed) {
				player = new Player(20, "orange");
				if (zSpawned === false) {
					enemyInt(amount, hp, speed);
					zSpawned = true;
				}
				createBackground();
			}
			gameLoop();
		}
	}, [ready]);
	useEffect(() => {
		return () => {
			window.cancelAnimationFrame(id.current);
			setReady(false);
		};
	}, []);
	return (
		<>
			{!touch ? (
				<ProjectShowCaseWrapper>
					<GameScreen ref={wrapper} id="gameScreen"></GameScreen>
					<PropImages id="player" src={playerIMG}></PropImages>
					<PropImages id="zombie" src={zIMG}></PropImages>
					<PropImages id="background" src={background}></PropImages>
				</ProjectShowCaseWrapper>
			) : (
				<ProjectMobileShowCase>
					<ProjectGIF src={gif}></ProjectGIF>
					<MobileDescription>
						Unfortunetly this showcase is not available on mobile devices.
						Feel free to switch to a desktop computer in order to play the
						game.
					</MobileDescription>
				</ProjectMobileShowCase>
			)}
		</>
	);
};
