import styled from "styled-components";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

export const ButtonWrapper = styled.div`
	position: absolute;
	bottom: 20px;
	right: 40px;
	display: none;
	z-index: 9;
	@media screen and (max-width: 767px) {
		display: ${(props) => (props.active ? "block" : "none")};
	}
	@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		display: ${(props) => (props.active ? "block" : "none")};
	}
`;
export const Button = styled.a`
	background-color: #ffffff40;
	backdrop-filter: blur(4px);
	--webkit-backdrop-filter: blur(2px);
	border-radius: 5px;
	border: none;
	padding: 5px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const ButtonIcon = styled(MdOutlineKeyboardArrowUp)`
	font-size: 2rem;
	color: var(--contrast-o);
`;
